import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { GdprConsent } from "./local-storage.service";
import { LoggingService } from "./log.service";

/** @ignore */
const TAG = "IAMService";

/** Product ID of InkCapture. */
export const PRODUCT_ID = "60be4dd732ddad25d2ff6160";

/**
 * Response object that doesn't carry more data
 * than status and message info.
 */
export type SimpleResponse = {
  message: string;
  status: string;
};

/**
 * Login response object that is returned from IAM
 * when accessing /login endpoint. If status is OK
 * then the response contains a token.
 */
export type GdprResponse = {
  message: string;
  status: string;
  consent?: string;
};

/**
 * Login response object that is returned from IAM
 * when accessing /login endpoint. If status is OK
 * then the response contains a token.
 */
export type LoginResponse = {
  message: string;
  status: string;
  token?: string;
};

/**
 * Profile response object when calling
 * IAM /profile endpoint.
 */
export type ProfileResponse = {
  message: string;
  status: string;
  user?: {
    id: string;
    name: string;
    surname: string;
    /**
     * This is user's e-mail address.
     */
    username: string;
  };
};

/**
 * Account state response object that is returned from IAM
 * when accessing /getAccountState endpoint.
 */
export type AccountStateResponse = {
  message: "string";
  status: "string";
  availableBudget: 0;
  budgetExpiration: "string";
  availableStorage: 0;
  storageExpiration: "string";
};

@Injectable({
  providedIn: "root",
})
export class IamService {
  constructor(private http: HttpClient, private logService: LoggingService) {}

  /**
   * Saves GDPR consents on IAM server.
   * @param consents Array of GDPR consents.
   */
  async saveGdprConsents(consents: GdprConsent[]) {
    this.http
      .post(`${environment.iam_server}/save-gdpr-consent`, {
        consent: JSON.stringify(consents),
        productID: PRODUCT_ID,
      })
      .subscribe({
        next: (res) => {
          this.logService.info(TAG, "GDPR consent saved to server.", consents);
        },
        error: (err) => {
          this.logService.error(TAG, "GDPR consent save error.", err);
        },
      });
  }

  /**
   * Get user's latest GDPR consent.
   * @returns Promise<GdprResponse>
   */
  getLastestGdprConsent() {
    return this.http
      .post<GdprResponse>(`${environment.iam_server}/get-latest-gdpr-consent`, {
        productID: PRODUCT_ID,
      })
      .toPromise();
  }

  /**
   * Simple get request without parameters uses a JWT token
   * sent in the Authorization header to return user's info.
   * @returns Promise<ProfileResponse>
   */
  getProfileInfo() {
    return this.http
      .get<ProfileResponse>(`${environment.iam_server}/profile`)
      .toPromise();
  }

  /**
   * Update user's profile info.
   * @param language
   * @returns Promise<LoginResponse>
   */
  updateUserProfile(language: string) {
    return this.http
      .post<SimpleResponse>(`${environment.iam_server}/update-profile`, {
        language: language,
        productID: PRODUCT_ID,
      })
      .toPromise();
  }

  /**
   * Authenticate user against IAM server.
   * @param email
   * @param password
   * @returns Promise<LoginResponse>
   */
  login(email: string, password: string) {
    return this.http
      .post<LoginResponse>(`${environment.iam_server}/login`, {
        email: email,
        password: password,
      })
      .toPromise();
  }

  /**
   * Get user's account state.
   * @returns Promise<LoginResponse>
   */
  getAccountState() {
    return this.http
      .get<AccountStateResponse>(`${environment.iam_server}/get-account-state`)
      .toPromise();
  }
}
