import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import {
  BehaviorSubject,
  fromEvent,
  Observable,
  Subject,
  Subscription,
} from "rxjs";
import { HighlightType, ShowTextType } from "../models/ui.model";
import { environment } from "src/environments/environment";
import { AppMode, ContentSource } from "../models/common";

const zoomValues: number[] = [
  0.1, 0.17, 0.25, 0.3, 0.4, 0.5, 0.75, 1.0, 1.5, 2.0, 3.0,
];

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  readonly PAGE_LIST_STEP = 3;

  readonly breakpoints: { [key: string]: number } = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  };

  private viewOptions: ViewOptions;
  private viewOptions$ = new BehaviorSubject<ViewOptions>({
    highlightType: HighlightType.None,
    showTextType: ShowTextType.SelectedWords,
    zoom: 0.5,
    textSize: 1,
  });

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  private fitToScreenObservable$: Subject<void> = new Subject<void>();
  private appMode: AppMode;
  private contentSource: ContentSource;
  public windowWidth: number;

  constructor(private platform: Platform) {
    this.appMode = environment.app_mode;
    this.contentSource = environment.mock_data
      ? ContentSource.DEMO
      : ContentSource.ICWS;
    this.windowWidth = window.innerWidth;
    this.resizeObservable$ = fromEvent(window, "resize");
    this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
      this.windowWidth = window.innerWidth;
    });
    this.viewOptions = this.viewOptions$.value;
  }

  currentContentSource(): ContentSource {
    return this.contentSource;
  }

  currentAppMode(): AppMode {
    return this.appMode;
  }

  getViewOptions(): Observable<ViewOptions> {
    return this.viewOptions$.asObservable();
  }

  getFitToScreenRequest(): Observable<void> {
    return this.fitToScreenObservable$.asObservable();
  }

  getCurrentHighlightViewOption(): number {
    return this.viewOptions.highlightType;
  }

  getCurrentShowTextViewOption(): number {
    return this.viewOptions.showTextType;
  }

  getCurrentZoomViewOption(): number {
    return this.viewOptions.zoom;
  }

  setAppMode(newAppMode: AppMode) {
    this.appMode = newAppMode;
  }

  setContentSource(newContentSource: ContentSource) {
    this.contentSource = newContentSource;
  }

  setHighlightViewOption(newHighlightType: HighlightType) {
    this.viewOptions.highlightType = newHighlightType;
    this.viewOptions$.next(this.viewOptions);
  }

  setShowTextViewOption(newshowTextType: ShowTextType) {
    this.viewOptions.showTextType = newshowTextType;
    this.viewOptions$.next(this.viewOptions);
  }

  setZoomViewOption(newZoom: number) {
    // Check whether the requested zoom is in allowed range
    if (newZoom > zoomValues[zoomValues.length - 1])
      newZoom = zoomValues[zoomValues.length - 1];
    if (newZoom < zoomValues[0]) newZoom = zoomValues[0];
    this.viewOptions.zoom = newZoom;
    this.viewOptions$.next(this.viewOptions);
  }

  setZoomViewOptionUp() {
    for (let i = 0; i < zoomValues.length; i++) {
      if (zoomValues[i] > this.viewOptions.zoom) {
        this.viewOptions.zoom = zoomValues[i];
        this.viewOptions$.next(this.viewOptions);
        break;
      }
    }
  }

  setZoomViewOptionDown() {
    for (let i = zoomValues.length; i >= 0; i--) {
      if (zoomValues[i] < this.viewOptions.zoom) {
        this.viewOptions.zoom = zoomValues[i];
        this.viewOptions$.next(this.viewOptions);
        break;
      }
    }
  }

  setTextSizeOption(newTextSize: number) {
    this.viewOptions.textSize = newTextSize;
    this.viewOptions$.next(this.viewOptions);
  }

  requestFitToScreen() {
    this.fitToScreenObservable$.next();
  }

  isMobilePlatform() {
    return this.platform.is("mobile") || this.platform.is("mobileweb");
  }

  isIOSPlatform() {
    return (
      this.platform.is("ios") ||
      this.platform.is("ipad") ||
      this.platform.is("iphone")
    );
  }
}

interface ViewOptions {
  highlightType: HighlightType;
  showTextType: ShowTextType;
  zoom: number;
  textSize: number;
}
