import { Injectable } from "@angular/core";
import {
  SetDocumentPermissionsRequest,
  SetDocumentPermissionsResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/auth/set_document_permissions_pb";
import { AuthClient } from "../proto/generated/icws_proto/icws_api_gateway/AuthServiceClientPb";
import { SharedService } from "./shared.service";
import { Struct } from "google-protobuf/google/protobuf/struct_pb";
import { environment } from "src/environments/environment";
import { Permission } from "../proto/generated/icws_proto/icws_api_gateway/auth/base_pb";

const TAG = "IcwsAuthService";

@Injectable({
  providedIn: "root",
})
export class IcwsAuthService {
  private authService: AuthClient;
  private _authMetadata = { authorization: null };

  constructor() {
    this.authService = new AuthClient(environment.icws_server, null, null);
  }

  async initMetadata(token: string) {
    if (token !== null) {
      this._authMetadata.authorization = token;
    }
  }

  clearMetadata() {
    this._authMetadata.authorization = null;
  }

  async setDocumentPermission(
    documentId: string,
    permission: DocumentPermission
  ): Promise<SetDocumentPermissionsResponse> {
    switch (permission) {
      case DocumentPermission.NONE:
        return this.setIcwsDocumentPermission(documentId, "user", null, []);
      case DocumentPermission.READ_FOR_EVERYONE:
        return this.setIcwsDocumentPermission(documentId, "user", null, ["read"]);
    }
  }

  isDocumentPermissionSet(
    permissions: Permission[],
    requestedPermission: DocumentPermission
  ): boolean {
    let isSet: boolean = false;

    if (requestedPermission === DocumentPermission.NONE && permissions.length === 0) {
      return true;
    }
    for (let permission of permissions) {
      switch (requestedPermission) {
        case DocumentPermission.READ_FOR_EVERYONE:
          isSet = this.checkPermissionReadForEveryone(permission);
          break;
      }
      if (isSet) return true;
    }
    return false;
  }

  private checkPermissionReadForEveryone(permission: Permission): boolean {
    if (
      permission.getObject().getType() === "user" &&
      !permission.getObject().getSpec() &&
      permission.getActivity() == "read"
    )
      return true;
    else return false;
  }

  private async setIcwsDocumentPermission(
    documentId: string,
    objectType: string,
    objectSpec: string,
    activites: string[]
  ): Promise<SetDocumentPermissionsResponse> {
    const reqGUID: string = SharedService.generateGuid();
    const request = new SetDocumentPermissionsRequest();
    request.setRequestId(reqGUID);
    request.setDocumentId(documentId);
    request.setObjectType(objectType);
    const preparedObjectSpec = JSON.parse(JSON.stringify(objectSpec));
    request.setObjectSpec();
    Struct.fromJavaScript(preparedObjectSpec);
    request.setActivitiesList(activites);
    return this.authService.setDocumentPermissions(request, this._authMetadata);
  }
}

export enum DocumentPermission {
  NONE = 0,
  READ_FOR_EVERYONE = 1,
}
