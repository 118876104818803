import { Injectable } from "@angular/core";
import { SearchingClient } from "../proto/generated/icws_proto/icws_api_gateway/SearchingServiceClientPb";
import { environment } from "src/environments/environment";
import {
  FindTextRequest,
  FindTextResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/searching/find_text_pb";
import { SharedService } from "./shared.service";
import { LoggingService } from "./log.service";

const TAG = "IcwsSearchingService";

@Injectable({
  providedIn: "root",
})
export class IcwsSearchingService {
  searchingService: SearchingClient;
  private _authMetadata = { authorization: null };

  constructor(private sharedService: SharedService, private logService: LoggingService) {
    this.searchingService = new SearchingClient(environment.icws_server, null, null);
  }

  async initMetadata(token: string) {
    if (token !== null) {
      this._authMetadata.authorization = token;
    }
  }

  clearMetadata() {
    this._authMetadata.authorization = null;
  }

  //
  // FIND TEXT
  //
  async findText(es_query: string): Promise<FindTextResponse> {
    const reqGUID: string = SharedService.generateGuid();
    const request = new FindTextRequest();
    request.setRequestId(reqGUID);
    request.setEsQuery(es_query);
    return this.sharedService.retryPromise(
      () => this.searchingService.findText(request, this._authMetadata),
      "findText()",
      { es_query: es_query }
    );
  }
}
