import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthSharedGuard } from "./guards/auth-shared.guard";
import { AuthGuard } from "./guards/auth.guard";
import { AutoLoginGuard } from "./guards/auto-login.guard";
import { IntroGuard } from "./guards/intro.guard";

const routes: Routes = [
  {
    path: "test-data",
    loadChildren: () =>
      import("./pages/test-data/test-data.module").then((m) => m.TestDataPageModule),
  },
  {
    path: "intro",
    loadChildren: () => import("./pages/intro/intro.module").then((m) => m.IntroPageModule),
  },
  {
    path: "login",
    loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginPageModule),
    canLoad: [AutoLoginGuard], //IntroGuard,
  },
  {
    path: "shared-doc",
    loadChildren: () => import("./pages/main/main.module").then((m) => m.MainPageModule),
    canLoad: [AuthSharedGuard],
  },
  {
    path: "",
    loadChildren: () => import("./pages/main/main.module").then((m) => m.MainPageModule),
    canLoad: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: "reload",
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
