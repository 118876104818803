import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as xmlParser from "fast-xml-parser";
import {
  IccContent,
  IccDocument,
  IccFolder,
  IccPage,
  IccTask,
  ROOT_NODE_NAME,
  StructuredContent,
} from "../models/icc-content.model";
import { SharedService } from "./shared.service";
import { environment } from "src/environments/environment";
import { ContentSource } from "../models/common";
import {
  ProcessedScanStateType,
  TaskStateType,
} from "../proto/generated/icws_proto/icws_api_gateway/types_pb";
import { OcrService } from "./page-ocr.service";

export enum MockOCRType {
  JSON_COCO = 0,
  XML_ALTO = 1,
}

/**
 * Symbolic ID for root node in hierarchy - in backend is represented as "null",
 * in hierarchy it is usefull to have a name for it to clearly reference to it.
 */
export const MOCK_ROOT_NODE_ID = "root";
const ALTO_TAG_LINE = "TextLine";
const ALTO_TAG_WORD = "String";

@Injectable({
  providedIn: "root",
})
export class MockDataService {
  constructor(private httpClient: HttpClient) {}

  getMockContentFlat(contentSource: ContentSource): IccContent[] {
    switch (contentSource) {
      case ContentSource.DEMO:
        return [...mock_content_demo];
      case ContentSource.APP_TOUR:
        return [...mock_content_app_tour_cs];
      default:
        return null;
    }
  }

  getMockPageText(
    page: IccPage
  ): Promise<{ plainText: string; structuredText: StructuredContent }> {
    return new Promise((resolve) => {
      if (environment.mock_ocr_type == MockOCRType.XML_ALTO) {
        this.pageContentFromAlto("assets/test_images/alto/alto_" + page.name + ".xml").then(
          (pageContentData) => {
            resolve({
              plainText: pageContentData.plainTextContent,
              structuredText: pageContentData.structuredContent,
            });
          }
        );
      } else if (environment.mock_ocr_type == MockOCRType.JSON_COCO) {
        // const fileNumber = documentId.substring(documentId.lastIndexOf("_") + 1);
        const fileNumber = page.parentId.substring(page.parentId.lastIndexOf("_") + 1);
        const jsonLinesFile = "assets/mock_data/demo_lines_" + fileNumber + ".json";
        const jsonWordsFile = "assets/mock_data/demo_words_" + fileNumber + ".json";
        this.pageContentFromJson(jsonLinesFile, jsonWordsFile, page.icwsId).then(
          (pageContentData) => {
            resolve({
              plainText: pageContentData.plainTextContent,
              structuredText: pageContentData.structuredContent,
            });
          }
        );
      }
    });
  }

  async getMockPageImage(pageId: string): Promise<string> {
    const file = await fetch("assets/mock_data/" + pageId);
    const fileData = await file.arrayBuffer();
    const fileUintData = new Uint8Array(fileData);
    const base64Image = SharedService.convertUint8ToBase64(fileUintData);
    return base64Image;
  }

  private pageContentFromJson(
    jsonFileLines: string,
    jsonFileWords: string,
    pageName: string
  ): Promise<PageTextContent> {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.httpClient.get(jsonFileLines).toPromise(),
        this.httpClient.get(jsonFileWords).toPromise(),
      ])
        .then((results) => {
          resolve(this.processJson(results[0], results[1], pageName));
        })
        .catch((error) => reject(error));
    });
  }

  private pageContentFromAlto(altoFile: string): Promise<PageTextContent> {
    const parseOptions = {
      attributeNamePrefix: "",
      ignoreAttributes: false,
      ignoreNameSpace: true,
      allowBooleanAttributes: true,
      parseNodeValue: true,
      parseAttributeValue: true,
      trimValues: true,
    };
    return new Promise((resolve, reject) => {
      this.httpClient.get(altoFile, { responseType: "text" }).subscribe(
        (data) => {
          var jsonObj = xmlParser.parse(data, parseOptions);
          resolve(this.processAlto(jsonObj));
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  private processJson(
    originalJsonLinesData: any,
    originalJsonWordsData: any,
    pageName: string
  ): PageTextContent {
    let plainText = "";
    let structured = "";
    structured = structured + '{ "lines": [ ';
    const pageId = pageName.substring(pageName.lastIndexOf("_") + 1, pageName.lastIndexOf("."));
    const documentDataLines = originalJsonLinesData.annotations.filter((s) => s.image_id == pageId);
    const pageDim = mock_document_size.find((page) => page.pageId == pageName);
    const pageWidth = pageDim.width ? pageDim.width : 1;
    const pageHeight = pageDim.height ? pageDim.height : 1;
    if (documentDataLines) {
      for (let annotationLines of documentDataLines) {
        plainText = plainText + " " + annotationLines.text + "\n";
        structured =
          structured +
          ' {"guid":"' +
          SharedService.generateGuid() +
          '", "boundingBox": {"x":' +
          annotationLines.bbox[0] / pageWidth +
          ', "y":' +
          annotationLines.bbox[1] / pageHeight +
          ', "width":' +
          annotationLines.bbox[2] / pageWidth +
          ', "height":' +
          annotationLines.bbox[3] / pageHeight +
          '}, "words": [ ';

        const documentDatawords = originalJsonWordsData.annotations.filter(
          (s) => s.line_id == annotationLines.id
        );
        if (documentDatawords.length > 0) {
          for (let annotationWords of documentDatawords) {
            let text: string = annotationWords.text
              .replace("/_", "")
              .replace("_/", "")
              .replace('"', '\\"');
            structured =
              structured +
              ' {"guid":"' +
              SharedService.generateGuid() +
              '", "content":"' +
              text +
              '", "charsCertainty": ' +
              this.genCharCertaintyJson(text.length) +
              ', "wordCertainty": ' +
              this.genRandom(0.6, 0.9, 1.0).toFixed(1) +
              ', "boundingBox": { "x":' +
              annotationWords.bbox[0] / pageWidth +
              ', "y":' +
              annotationWords.bbox[1] / pageHeight +
              ', "width":' +
              annotationWords.bbox[2] / pageWidth +
              ', "height":' +
              annotationWords.bbox[3] / pageHeight +
              " }}, ";
          }
          structured = structured.slice(0, -2) + " ] }, "; // remove last ", " and add final closing bracket
        } else {
          structured = structured + " ] }, "; // no words found - add close brackets (empty array)
        }
      }
      structured = structured.slice(0, -2) + " ] }"; // remove last ", " and add final closing bracket
    }
    return {
      plainTextContent: plainText,
      structuredContent: JSON.parse(structured),
    };
  }

  private processAlto(originalAltoData: any): PageTextContent {
    let plainText = "";
    let structured = "";
    structured = structured + '{ "lines": [ ';
    let altoLines = this.findKeys(originalAltoData, ALTO_TAG_LINE);
    for (let line of altoLines) {
      let altoWords = this.findKeys(line, ALTO_TAG_WORD);
      structured =
        structured +
        ' { "boundingBox": { "x":"' +
        line.HPOS +
        '", "y":"' +
        line.VPOS +
        '", "width":"' +
        line.WIDTH +
        '", "height":"' +
        line.HEIGHT +
        '" }, ';
      structured = structured + '"words": [ ';
      for (let word of altoWords) {
        plainText = plainText + " " + word.CONTENT;
        structured =
          structured +
          '{ "content":"' +
          word.CONTENT +
          '", "boundingBox": { "x":"' +
          word.HPOS +
          '", "y":"' +
          word.VPOS +
          '", "width":"' +
          word.WIDTH +
          '", "height":"' +
          word.HEIGHT +
          '" } }, ';
      }
      structured = structured.slice(0, -2) + " ] }, "; // remove last ", " and add closing bracket
      plainText = plainText + "\n";
    }
    structured = structured.slice(0, -2) + " ] }"; // remove last ", " and add final closing bracket
    plainText = plainText.slice(0, -1); // remove last "\n"
    plainText = SharedService.removeEscapedChars(plainText);
    return {
      plainTextContent: plainText,
      structuredContent: JSON.parse(structured),
    };
  }

  private genRandom(min: number, mid: number, max): number {
    let randNum = (Math.random() + Math.random() + Math.random() + Math.random()) / 4;
    let rand = randNum * (mid - min) * 2 + min;
    return rand > max ? max : rand < min ? min : rand;
  }

  private genCharCertaintyJson(textLenght: number): string {
    let charConfArray = "[";
    for (let i = 0; i < textLenght; i++) {
      charConfArray = charConfArray.concat((this.genRandom(0.6, 1.0, 1.0) * 10).toFixed(0));
      if (i < textLenght - 1) charConfArray = charConfArray.concat(",");
    }
    charConfArray = charConfArray.concat("]");
    return charConfArray;
  }

  private findKeys(source: any, keyToSearch: string): any[] {
    var result = [];

    function recursivelyFindKey(xSource, xKeyToSearch: string) {
      Object.keys(xSource).forEach(function (key) {
        if (key == xKeyToSearch) {
          if (Array.isArray(xSource[key])) {
            for (let item of xSource[key]) {
              result.push(item);
            }
          } else {
            result.push(xSource[key]);
          }
        }
        if (typeof xSource[key] === "object") {
          recursivelyFindKey(xSource[key], xKeyToSearch);
        }
      });
    }

    recursivelyFindKey(source, keyToSearch);
    return result;
  }

  getMockOcrTask(documentId: string): IccTask {
    // For document "app_tour_document_02" emulate state without any processing
    if (documentId.includes("app_tour_document_02")) {
      return null;
    } else {
      return {
        icwsId: documentId + "_ocr",
        params: OcrService.getGeneralProcessingParams(),
        state: TaskStateType.TASK_STATE_TYPE_FINISHED_OK,
        progress: 1,
        created_at: new Date(2023, 0, 20, 10, 0),
        finished_at: new Date(2023, 0, 20, 10, 15),
        pageNumStarted: 2,
        pageNumFinished: new Map([
          [ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_FINISHED_OK, 2],
        ]),
      };
    }
  }
}

export interface PageTextContent {
  plainTextContent: string;
  structuredContent: any;
}

const mock_content_app_tour_cs: IccContent[] = [
  new IccFolder({
    icwsId: MOCK_ROOT_NODE_ID,
    name: ROOT_NODE_NAME,
    parentId: null,
    valid: true,
  }),
  new IccFolder({
    icwsId: "app_tour_folder_1",
    name: "Vzorová složka 1",
    parentId: MOCK_ROOT_NODE_ID,
    valid: true,
  }),
  new IccFolder({
    icwsId: "app_tour_folder_2",
    name: "Vzorová složka 2",
    parentId: MOCK_ROOT_NODE_ID,
    valid: true,
  }),
  new IccFolder({
    icwsId: "app_tour_folder_3",
    name: "Vzorová složka 3",
    parentId: "app_tour_folder_1",
    valid: true,
  }),
  new IccDocument({
    icwsId: "app_tour_document_01",
    name: "Vzorový dokument",
    parentId: MOCK_ROOT_NODE_ID,
    valid: true,
    thumbnailPageId: "apptour_01_01_0007_22.jpg",
    scans_num: 2,
  }),
  new IccPage({
    icwsId: "apptour_01_01_0007_22.jpg",
    name: "strana",
    order: 1,
    parentId: "app_tour_document_01",
    valid: true,
  }),
  new IccPage({
    icwsId: "apptour_01_02_0008_16.jpg",
    name: "strana",
    order: 2,
    parentId: "app_tour_document_01",
    valid: true,
  }),
  new IccDocument({
    icwsId: "app_tour_document_02",
    name: "Vzorový dokument",
    parentId: MOCK_ROOT_NODE_ID,
    valid: true,
    thumbnailPageId: "apptour_01_01_0007_22.jpg",
    scans_num: 2,
  }),
  new IccPage({
    icwsId: "apptour_01_01_0007_22.jpg",
    name: "strana",
    order: 1,
    parentId: "app_tour_document_02",
    valid: true,
  }),
  new IccPage({
    icwsId: "apptour_01_02_0008_16.jpg",
    name: "strana",
    order: 2,
    parentId: "app_tour_document_02",
    valid: true,
  }),
];

const mock_content_demo: IccContent[] = [
  new IccFolder({
    icwsId: MOCK_ROOT_NODE_ID,
    name: ROOT_NODE_NAME,
    parentId: null,
    valid: true,
  }),
  new IccFolder({
    icwsId: "folder_1",
    name: "Školní poznámky",
    parentId: MOCK_ROOT_NODE_ID,
    valid: true,
  }),
  new IccFolder({
    icwsId: "folder_2",
    name: "Zápisky ze schůzek",
    parentId: MOCK_ROOT_NODE_ID,
    valid: true,
  }),
  new IccFolder({
    icwsId: "folder_3",
    name: "Historické rodinné dokumenty",
    parentId: MOCK_ROOT_NODE_ID,
    valid: true,
  }),
  new IccDocument({
    icwsId: "document_04",
    name: "Spis notářský 1921",
    parentId: MOCK_ROOT_NODE_ID,
    valid: true,
    thumbnailPageId: "demo_04_01_0028_43.jpg",
    scans_num: 4,
  }),
  new IccPage({
    icwsId: "demo_04_01_0028_43.jpg",
    name: "strana",
    order: 1,
    parentId: "document_04",
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_04_02_0029_12.jpg",
    name: "strana",
    order: 2,
    parentId: "document_04",
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_04_03_0030_37.jpg",
    name: "strana",
    order: 3,
    parentId: "document_04",
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_04_04_0031_1.jpg",
    name: "strana",
    order: 4,
    parentId: "document_04",
    valid: true,
  }),
  new IccDocument({
    icwsId: "document_03",
    name: "Kvitance a prohlášení 377/98",
    parentId: "folder_3",
    valid: true,
    thumbnailPageId: "demo_03_01_0007_22.jpg",
    scans_num: 2,
  }),
  new IccPage({
    icwsId: "demo_03_01_0007_22.jpg",
    name: "strana",
    order: 1,
    parentId: "document_03",
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_03_02_0008_16.jpg",
    name: "strana",
    order: 2,
    parentId: "document_03",
    valid: true,
  }),
  new IccDocument({
    icwsId: "document_05",
    name: "Světová poválečná literatura",
    parentId: "folder_1",
    valid: true,
    thumbnailPageId: "demo_05_0066_87.jpg",
    scans_num: 24,
  }),
  new IccPage({
    icwsId: "demo_05_0066_87.jpg",
    name: "strana",
    parentId: "document_05",
    order: 1,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0067_137.jpg",
    name: "strana",
    parentId: "document_05",
    order: 2,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0068_15.jpg",
    name: "strana",
    parentId: "document_05",
    order: 3,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0069_115.jpg",
    name: "strana",
    parentId: "document_05",
    order: 4,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0070_102.jpg",
    name: "strana",
    parentId: "document_05",
    order: 5,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0071_20.jpg",
    name: "strana",
    parentId: "document_05",
    order: 6,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0072_38.jpg",
    name: "strana",
    parentId: "document_05",
    order: 7,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0073_41.jpg",
    name: "strana",
    parentId: "document_05",
    order: 8,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0074_49.jpg",
    name: "strana",
    parentId: "document_05",
    order: 9,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0075_113.jpg",
    name: "strana",
    parentId: "document_05",
    order: 10,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0076_43.jpg",
    name: "strana",
    parentId: "document_05",
    order: 11,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0077_125.jpg",
    name: "strana",
    parentId: "document_05",
    order: 12,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0078_17.jpg",
    name: "strana",
    parentId: "document_05",
    order: 13,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0079_146.jpg",
    name: "strana",
    parentId: "document_05",
    order: 14,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0080_14.jpg",
    name: "strana",
    parentId: "document_05",
    order: 15,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0081_56.jpg",
    name: "strana",
    parentId: "document_05",
    order: 16,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0082_63.jpg",
    name: "strana",
    parentId: "document_05",
    order: 17,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0083_98.jpg",
    name: "strana",
    parentId: "document_05",
    order: 18,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0084_123.jpg",
    name: "strana",
    parentId: "document_05",
    order: 19,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0085_68.jpg",
    name: "strana",
    parentId: "document_05",
    order: 20,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0086_65.jpg",
    name: "strana",
    parentId: "document_05",
    order: 21,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0087_44.jpg",
    name: "strana",
    parentId: "document_05",
    order: 22,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0088_4.jpg",
    name: "strana",
    parentId: "document_05",
    order: 23,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_05_0089_77.jpg",
    name: "strana",
    parentId: "document_05",
    order: 24,
    createdTime: new Date(),
    valid: true,
  }),
  new IccDocument({
    icwsId: "document_06",
    name: "Česká literatura po roce 1945",
    parentId: "folder_1",
    valid: true,
    thumbnailPageId: "demo_06_0089_77.jpg",
    scans_num: 14,
  }),
  new IccPage({
    icwsId: "demo_06_0089_77.jpg",
    name: "strana",
    parentId: "document_06",
    order: 1,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_06_0090_136.jpg",
    name: "strana",
    parentId: "document_06",
    order: 2,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_06_0091_139.jpg",
    name: "strana",
    parentId: "document_06",
    order: 3,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_06_0092_48.jpg",
    name: "strana",
    parentId: "document_06",
    order: 4,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_06_0093_107.jpg",
    name: "strana",
    parentId: "document_06",
    order: 5,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_06_0094_104.jpg",
    name: "strana",
    parentId: "document_06",
    order: 6,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_06_0095_91.jpg",
    name: "strana",
    parentId: "document_06",
    order: 7,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_06_0096_66.jpg",
    name: "strana",
    parentId: "document_06",
    order: 8,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_06_0097_145.jpg",
    name: "strana",
    parentId: "document_06",
    order: 9,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_06_0098_142.jpg",
    name: "strana",
    parentId: "document_06",
    order: 10,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_06_0099_108.jpg",
    name: "strana",
    parentId: "document_06",
    order: 11,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_06_0100_129.jpg",
    name: "strana",
    parentId: "document_06",
    order: 12,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_06_0101_124.jpg",
    name: "strana",
    parentId: "document_06",
    order: 13,
    createdTime: new Date(),
    valid: true,
  }),
  new IccPage({
    icwsId: "demo_06_0102_53.jpg",
    name: "strana",
    parentId: "document_06",
    order: 14,
    createdTime: new Date(),
    valid: true,
  }),
];

const mock_document_size = [
  {
    pageId: "apptour_01_01_0007_22.jpg",
    width: 638,
    height: 1002,
  },
  {
    pageId: "apptour_01_02_0008_16.jpg",
    width: 638,
    height: 1002,
  },
  {
    pageId: "demo_03_01_0007_22.jpg",
    width: 1275,
    height: 2004,
  },
  {
    pageId: "demo_03_02_0008_16.jpg",
    width: 1275,
    height: 2004,
  },
  {
    pageId: "demo_04_01_0028_43.jpg",
    width: 1240,
    height: 1987,
  },
  {
    pageId: "demo_04_02_0029_12.jpg",
    width: 1240,
    height: 1987,
  },
  {
    pageId: "demo_04_03_0030_37.jpg",
    width: 1240,
    height: 1987,
  },
  {
    pageId: "demo_04_04_0031_1.jpg",
    width: 1240,
    height: 1987,
  },
  {
    pageId: "demo_05_0066_87.jpg",
    width: 1252,
    height: 1834,
  },
  {
    pageId: "demo_05_0067_137.jpg",
    width: 1238,
    height: 1844,
  },
  {
    pageId: "demo_05_0068_15.jpg",
    width: 1252,
    height: 1834,
  },
  {
    pageId: "demo_05_0069_115.jpg",
    width: 1238,
    height: 1844,
  },
  {
    pageId: "demo_05_0070_102.jpg",
    width: 1252,
    height: 1834,
  },
  {
    pageId: "demo_05_0071_20.jpg",
    width: 1238,
    height: 1844,
  },
  {
    pageId: "demo_05_0072_38.jpg",
    width: 1252,
    height: 1834,
  },
  {
    pageId: "demo_05_0073_41.jpg",
    width: 1238,
    height: 1844,
  },
  {
    pageId: "demo_05_0074_49.jpg",
    width: 1252,
    height: 1834,
  },
  {
    pageId: "demo_05_0075_113.jpg",
    width: 1238,
    height: 1844,
  },
  {
    pageId: "demo_05_0076_43.jpg",
    width: 1252,
    height: 1834,
  },
  {
    pageId: "demo_05_0077_125.jpg",
    width: 1238,
    height: 1844,
  },
  {
    pageId: "demo_05_0078_17.jpg",
    width: 1252,
    height: 1834,
  },
  {
    pageId: "demo_05_0079_146.jpg",
    width: 1250,
    height: 1844,
  },
  {
    pageId: "demo_05_0080_14.jpg",
    width: 1252,
    height: 1834,
  },
  {
    pageId: "demo_05_0081_56.jpg",
    width: 1222,
    height: 1844,
  },
  {
    pageId: "demo_05_0082_63.jpg",
    width: 1252,
    height: 1834,
  },
  {
    pageId: "demo_05_0083_98.jpg",
    width: 1252,
    height: 1844,
  },
  {
    pageId: "demo_05_0084_123.jpg",
    width: 1252,
    height: 1834,
  },
  {
    pageId: "demo_05_0085_68.jpg",
    width: 1264,
    height: 1844,
  },
  {
    pageId: "demo_05_0086_65.jpg",
    width: 1252,
    height: 1834,
  },
  {
    pageId: "demo_05_0087_44.jpg",
    width: 1264,
    height: 1844,
  },
  {
    pageId: "demo_05_0088_4.jpg",
    width: 1252,
    height: 1834,
  },
  {
    pageId: "demo_05_0089_77.jpg",
    width: 1264,
    height: 1844,
  },
  {
    pageId: "demo_06_0089_77.jpg",
    width: 1264,
    height: 1844,
  },
  {
    pageId: "demo_06_0090_136.jpg",
    width: 1252,
    height: 1834,
  },
  {
    pageId: "demo_06_0091_139.jpg",
    width: 1264,
    height: 1844,
  },
  {
    pageId: "demo_06_0092_48.jpg",
    width: 1252,
    height: 1834,
  },
  {
    pageId: "demo_06_0093_107.jpg",
    width: 1264,
    height: 1844,
  },
  {
    pageId: "demo_06_0094_104.jpg",
    width: 1252,
    height: 1834,
  },
  {
    pageId: "demo_06_0095_91.jpg",
    width: 1264,
    height: 1844,
  },
  {
    pageId: "demo_06_0096_66.jpg",
    width: 1252,
    height: 1834,
  },
  {
    pageId: "demo_06_0097_145.jpg",
    width: 1264,
    height: 1844,
  },
  {
    pageId: "demo_06_0098_142.jpg",
    width: 1252,
    height: 1834,
  },
  {
    pageId: "demo_06_0099_108.jpg",
    width: 1264,
    height: 1844,
  },
  {
    pageId: "demo_06_0100_129.jpg",
    width: 1252,
    height: 1834,
  },
  {
    pageId: "demo_06_0101_124.jpg",
    width: 1264,
    height: 1844,
  },
  {
    pageId: "demo_06_0102_53.jpg",
    width: 1252,
    height: 1834,
  },
];
