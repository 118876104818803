import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { SidePanelContainerComponent } from "./side-panel/side-panel-container/side-panel-container.component";
import { SidePanelComponent } from "./side-panel/side-panel/side-panel.component";
import { SidePanelContentComponent } from "./side-panel/side-panel-content/side-panel-content.component";
import { RouterModule } from "@angular/router";
import { IonCustomScrollbarModule } from "ion-custom-scrollbar";
import { MatSelectModule } from "@angular/material/select";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { TranslateModule } from "@ngx-translate/core";
import { PdfViewComponent } from "./pdf-view/pdf-view.component";
import { ContentTreeComponent } from "./content-tree/content-tree.component";
import { MatTreeModule } from "@angular/material/tree";
import { ContentTreeModalComponent } from "./content-tree-modal/modal-tree.component";
import { EditPopupComponent } from "./edit-popup/edit-popup.component";
import { RunOcrPopupComponent } from "./run-ocr-popup/run-ocr-popup.component";
import { ErrorWindowComponent } from "./error-window/error-window.component";
import { ButtonDropdownComponent } from "./button-dropdown/button-dropdown.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    IonCustomScrollbarModule,
    RouterModule,
    MatTreeModule,
    MatSelectModule,
    FontAwesomeModule,
    PdfViewerModule,
    TranslateModule,
  ],
  declarations: [
    SidePanelContainerComponent,
    SidePanelComponent,
    SidePanelContentComponent,
    PdfViewComponent,
    ContentTreeComponent,
    ContentTreeModalComponent,
    EditPopupComponent,
    RunOcrPopupComponent,
    ErrorWindowComponent,
    ButtonDropdownComponent,
  ],
  exports: [
    SidePanelContainerComponent,
    SidePanelComponent,
    SidePanelContentComponent,
    PdfViewComponent,
    ContentTreeComponent,
    ContentTreeModalComponent,
    EditPopupComponent,
    RunOcrPopupComponent,
    ErrorWindowComponent,
    ButtonDropdownComponent,
  ],
})
export class SharedComponentsModule {}
