import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { GestureController, GestureDetail, Platform } from "@ionic/angular";
import {
  LocalStorageService,
  STORAGE_GROUP_KEY_APP_STATE,
} from "src/app/services/local-storage.service";
import { GlobalConstants } from "src/app/models/common";

@Component({
  selector: "app-side-panel",
  templateUrl: "./side-panel.component.html",
  styleUrls: ["./side-panel.component.scss"],
})
export class SidePanelComponent implements OnChanges, AfterViewInit {
  @Input() panelStateKey: string;

  @Input() headerText: string = "";

  @Input() headerIcon: IconName = "compass";

  @Input() mode: string = "side"; // side, over

  @Input() position: string = "left"; // left, right

  @Input() size: number = 300;

  @Input() animationTime: string = "0.5s ease-out";

  @Input() containerPadding: string = "0px";

  @Input() contentPadding: string = "0px";

  @Input() headerStyle: string = "light"; // light, dark

  @Input() opened: boolean = false;

  @Output() openedChange = new EventEmitter<boolean>();

  // opened: boolean = false;
  currentPull: number = 0;
  swipeGestureRunning: boolean = false;
  swipeGestureInitialX: number;

  /** @ignore */
  @ViewChild("placeholder", { static: true }) placeholderElement: ElementRef;
  /** @ignore */
  @ViewChild("container", { static: true }) containerElement: ElementRef;
  /** @ignore */
  @ViewChild("handle", { static: true }) handleElement: ElementRef;

  @HostListener("window:resize", ["$event"]) onResize(event) {
    this.setHandlePositionAndSize();
  }

  constructor(
    private gestureCtrl: GestureController,
    public platform: Platform,
    private localStorage: LocalStorageService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.currentPull = this.opened ? this.size : 0;
    this.renderPanel();
  }

  async ngAfterViewInit() {
    if (this.panelStateKey) {
      // Load last panel state (opened/closed), default state is opened.
      this.localStorage
        .getGrouped(STORAGE_GROUP_KEY_APP_STATE, this.panelStateKey)
        .then((state) => {
          this.opened = state != null ? state : true;
          this.openedChange.emit(this.opened);
          this.currentPull = this.opened ? this.size : 0;
          this.setAnimationTime(this.animationTime);
          this.setHandlePositionAndSize();
          this.renderPanel();
        });
    }

    const gesturePanelContainer = this.gestureCtrl.create({
      el: this.containerElement.nativeElement,
      gestureName: "containerSwipe",
      direction: "x",
      threshold: 1,
      onMove: (gestureEvent) => {
        this.moveGesture(gestureEvent);
      },
      onEnd: (_) => {
        this.swipeGestureRunning = false;
      },
    });
    const gesturePanelHandle = this.gestureCtrl.create({
      el: this.handleElement.nativeElement,
      gestureName: "containerHandleSwipe",
      direction: "x",
      threshold: 1,
      onMove: (gestureEvent) => {
        this.moveGesture(gestureEvent);
      },
      onEnd: (_) => {
        this.swipeGestureRunning = false;
      },
    });

    gesturePanelContainer.enable(true);
    gesturePanelHandle.enable(true);
  }

  moveGesture(gestureEvent: GestureDetail) {
    if (
      !this.swipeGestureRunning &&
      Math.abs(gestureEvent.velocityX) >=
        GlobalConstants.GESTURE_SWIPE_MIN_VELOCITY
    ) {
      this.swipeGestureInitialX = Math.abs(gestureEvent.deltaX);
      this.swipeGestureRunning = true;
    }

    if (this.swipeGestureRunning) {
      if (
        Math.abs(gestureEvent.velocityX) <
        GlobalConstants.GESTURE_SWIPE_MIN_VELOCITY
      ) {
        this.swipeGestureRunning = false;
      }

      if (
        Math.abs(gestureEvent.deltaX) >=
        this.swipeGestureInitialX + GlobalConstants.GESTURE_SWIPE_MIN_DISTANCE
      ) {
        // SWIPE ACTIVATED
        this.swipeGestureRunning = false;
        if (gestureEvent.deltaX > 0) {
          // swipe right
          this.swipeRight();
        } else {
          // swipe left
          this.swipeLeft();
        }
      }
    }
  }

  renderPanel() {
    if (this.position == "left") {
      this.setLeftPanelCSS();
    } else {
      this.setRightPanelCSS();
    }
  }

  setLeftPanelCSS() {
    this.placeholderElement.nativeElement.style.left = "0px";
    this.placeholderElement.nativeElement.style.width = this.currentPull + "px";
    this.placeholderElement.nativeElement.style.position =
      this.mode == "side" ? "relative" : "absolute";

    this.containerElement.nativeElement.style.left =
      "-" + (this.size - this.currentPull) + "px";
    this.containerElement.nativeElement.style.width = this.size + "px";
    this.containerElement.nativeElement.style.padding = this.containerPadding;
    this.containerElement.nativeElement.style.borderRadius =
      "0px 16px 16px 0px";
    this.containerElement.nativeElement.style.boxShadow =
      "6px 6px 12px rgb(0, 0 ,0, 0.2)";

    this.handleElement.nativeElement.style.left = this.size + "px";
  }

  setRightPanelCSS() {
    this.placeholderElement.nativeElement.style.left =
      "calc(100% - " + this.currentPull + "px)";
    this.placeholderElement.nativeElement.style.width = this.currentPull + "px";
    this.placeholderElement.nativeElement.style.position =
      this.mode == "side" ? "relative" : "absolute";

    this.containerElement.nativeElement.style.left =
      "calc(100% - " + this.currentPull + "px)";
    this.containerElement.nativeElement.style.width = this.size + "px";
    this.containerElement.nativeElement.style.padding = this.containerPadding;
    this.containerElement.nativeElement.style.borderRadius =
      "16px 0px 0px 16px";
    this.containerElement.nativeElement.style.boxShadow =
      "-6px 6px 12px rgb(0, 0 ,0, 0.2)";
  }

  setAnimationTime(time: string) {
    this.placeholderElement.nativeElement.style.transition = time;
    this.containerElement.nativeElement.style.transition = time;
    this.handleElement.nativeElement.style.transition = time;
  }

  setHandlePositionAndSize() {
    const spaceForPanelHeight = this.platform.height() - 54 - 72; // window size - main header height - button bar height
    this.handleElement.nativeElement.style.bottom =
      (spaceForPanelHeight * 0.4).toFixed() + "px";
    this.handleElement.nativeElement.style.height =
      (this.platform.height() * 0.12).toFixed() + "px";
  }

  toggleOpened() {
    this.setAnimationTime(this.animationTime);
    this.opened = !this.opened;
    this.openedChange.emit(this.opened);
    if (this.position == "left") {
      this.currentPull = this.opened ? this.size : 0;
    } else {
      this.currentPull = this.opened ? this.size : 0;
    }
    this.savePanelOpenedState();
    this.renderPanel();
  }

  swipeLeft() {
    this.currentPull = this.position == "left" ? 0 : this.size;
    this.opened = this.position == "left" ? false : true;
    this.openedChange.emit(this.opened);
    this.savePanelOpenedState();
    this.renderPanel();
  }

  swipeRight() {
    this.currentPull = this.position == "left" ? this.size : 0;
    this.opened = this.position == "left" ? true : false;
    this.openedChange.emit(this.opened);
    this.savePanelOpenedState();
    this.renderPanel();
  }

  savePanelOpenedState() {
    if (this.panelStateKey) {
      this.localStorage.setGrouped(
        STORAGE_GROUP_KEY_APP_STATE,
        this.panelStateKey,
        this.opened
      );
    }
  }
}
