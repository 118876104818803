import { Injectable } from "@angular/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { StorageClient } from "../proto/generated/icws_proto/icws_api_gateway/StorageServiceClientPb";
import {
  GetDocumentRequest,
  GetDocumentResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/get_document_pb";
import {
  CreateDocumentRequest,
  CreateDocumentResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/create_document_pb";
import {
  DeleteDocumentsRequest,
  DeleteDocumentsResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/delete_documents_pb";
import { ImageVariantType } from "../proto/generated/icws_proto/icws_api_gateway/storage/storage_types_pb";
import {
  QuotaType,
  UpdateStrategyType,
} from "../proto/generated/icws_proto/icws_api_gateway/types_pb";
import { LoggingService } from "./log.service";
import { SharedService } from "./shared.service";
import { MockDataService } from "./mock-data.service";

import * as google_protobuf_struct_pb from "google-protobuf/google/protobuf/struct_pb";
import {
  AddScanToDocumentRequest,
  AddScanToDocumentResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/add_scan_to_document_pb";
import {
  GetScanRequest,
  GetScanResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/get_scan_pb";
import {
  CreateFolderRequest,
  CreateFolderResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/create_folder_pb";
import {
  ListFolderRequest,
  ListFolderResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/list_folder_pb";
import {
  DeleteFoldersRequest,
  DeleteFoldersResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/delete_folders_pb";
import {
  UpdateDocumentsRequest,
  UpdateDocumentsResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/update_documents_pb";
import {
  UpdateFoldersRequest,
  UpdateFoldersResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/update_folders_pb";
import {
  MoveDocumentsRequest,
  MoveDocumentsResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/move_documents_pb";
import {
  MoveFoldersRequest,
  MoveFoldersResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/move_folders_pb";
import { environment } from "src/environments/environment";
import { LocalStorageService, STORAGE_KEY_PAGE_CACHE } from "./local-storage.service";
import { AppMode, ContentSource, GlobalConstants } from "src/app/models/common";
import {
  UpdateScansRequest,
  UpdateScansResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/update_scans_pb";
import { GlobalService } from "./global.service";
import {
  RemoveScansFromDocumentRequest,
  RemoveScansFromDocumentResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/remove_scans_from_document_pb";
import { asyncScheduler, Subject } from "rxjs";
import { debounceTime, throttleTime } from "rxjs/operators";
import {
  GetFolderRequest,
  GetFolderResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/get_folder_pb";
import {
  GetLicenceInfoRequest,
  GetLicenceInfoResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/iam/get_licence_info_pb";
import { IAMClient } from "../proto/generated/icws_proto/icws_api_gateway/IamServiceClientPb";
import {
  QuotaInfo,
  QuotasPerTimePeriods,
} from "../proto/generated/icws_proto/icws_api_gateway/iam/base_pb";
import {
  GetRootFolderRequest,
  GetRootFolderResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/get_root_folder_pb";
import {
  GetProcessedScanRequest,
  GetProcessedScanResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/get_processed_scan_pb";

const TAG = "IcwsStorageService";
const STORAGE_INFO_UPDATE_INTERVAL = 1000 * 20; // Shortest interval between two storage updates [ms]

@Injectable({
  providedIn: "root",
})
export class IcwsStorageService {
  storageService: StorageClient;
  iamService: IAMClient;
  private _authMetadata = { authorization: null };
  private userStorageSource: Subject<void> = new Subject<void>();
  private userStorageInfo: UserStorageInfo;

  constructor(
    private logService: LoggingService,
    private localStorageService: LocalStorageService,
    private mockDataService: MockDataService,
    private globalService: GlobalService,
    private sharedService: SharedService
  ) {
    this.storageService = new StorageClient(environment.icws_server, null, null);
    this.iamService = new IAMClient(environment.icws_server, null, null);

    this.userStorageSource
      .pipe(
        // To ignore multiple requests comming in UPDATE_INTERVAL and perform update
        // only when there was any request during the time period.
        throttleTime(STORAGE_INFO_UPDATE_INTERVAL, asyncScheduler, {
          leading: true,
          trailing: true,
        }),
        // If requests are both before and after the end of period, "throttleTime" emits two events.
        // To avoid this behaviod, "debounceTime" will reduce it to one event.
        debounceTime(1000)
      )
      .subscribe(() => {
        this.performUserStorageUpdate();
      });
  }

  async initMetadata(token: string) {
    if (token !== null) {
      this._authMetadata.authorization = token;
    }
  }

  clearMetadata() {
    this._authMetadata.authorization = null;
  }

  //
  // FOLDER
  //

  async getRootFolder(): Promise<GetRootFolderResponse> {
    const reqGUID: string = SharedService.generateGuid();
    const request = new GetRootFolderRequest();
    request.setRequestId(reqGUID);
    return this.sharedService.retryPromise(
      () => this.storageService.getRootFolder(request, this._authMetadata),
      "getRootFolder()"
    );
  }

  async getFolder(folderId: string): Promise<GetFolderResponse> {
    const docGUID: string = SharedService.generateGuid();
    const request = new GetFolderRequest();
    request.setRequestId(docGUID);
    request.setId(folderId);
    return this.sharedService.retryPromise(
      () => this.storageService.getFolder(request, this._authMetadata),
      "getFolder()",
      { folderId: folderId }
    );
  }

  async createFolder(parentId: string, metadata: FolderMetadata): Promise<CreateFolderResponse> {
    const docGUID: string = SharedService.generateGuid();
    const request = new CreateFolderRequest();
    request.setRequestId(docGUID);
    request.setParentId(parentId);
    const preparedMetadata = JSON.parse(JSON.stringify(metadata)); // workaround to prevent error in calling "fromJavaScript"
    request.setMetadata(google_protobuf_struct_pb.Struct.fromJavaScript(preparedMetadata));
    return this.sharedService.retryPromise(
      () => this.storageService.createFolder(request, this._authMetadata),
      "createFolder()",
      { parentId: parentId, metadata: metadata }
    );
  }

  async listFolder(
    folderId: string,
    imageVariantType: ImageVariantType = null,
    includeScans: boolean = false,
    includeDeleted: boolean = false,
    rangeStart: number = 0,
    rangeSize: number = null
  ): Promise<ListFolderResponse> {
    const docGUID: string = SharedService.generateGuid();
    const request = new ListFolderRequest();
    request.setRequestId(docGUID);
    request.setId(folderId);
    request.setVariant(imageVariantType);
    request.setIncludeScans(includeScans);
    request.setIncludeDeleted(includeDeleted);
    request.setRangeStart(rangeStart);
    if (rangeSize != null) request.setRangeSize(rangeSize);
    return this.sharedService.retryPromise(
      () => this.storageService.listFolder(request, this._authMetadata),
      "listFolder()",
      {
        folderId: folderId,
        imageVariantType: imageVariantType,
        includeScans: includeScans,
        includeDeleted: includeDeleted,
        rangeStart: rangeStart,
        rangeSize: rangeSize,
      }
    );
  }

  async updateFolders(
    folderIds: string[],
    metadata: FolderMetadata,
    strategy: UpdateStrategyType = UpdateStrategyType.UPDATE_STRATEGY_REPLACE
  ): Promise<UpdateFoldersResponse> {
    return new Promise((resolve, reject) => {
      const docGUID: string = SharedService.generateGuid();
      const request = new UpdateFoldersRequest();
      request.setRequestId(docGUID);
      request.setIdsList(folderIds);
      request.setStrategy(strategy);
      const preparedMetadata = JSON.parse(JSON.stringify(metadata)); // workaround to prevent error in calling "fromJavaScript"
      request.setMetadata(google_protobuf_struct_pb.Struct.fromJavaScript(preparedMetadata));

      return this.sharedService
        .retryPromise(
          () => this.storageService.updateFolders(request, this._authMetadata),
          "updateFolders()",
          { folderIds: folderIds, metadata: metadata, strategy: strategy }
        )
        .then((response: UpdateFoldersResponse) => {
          // TODO: Check for returned IDs temporarily disabled. IDs are missing in the list even if everything is ok but nothing has changed
          // const updatedIds = response.getIdsList();
          // for (let folderId of folderIds) {
          //   if (!updatedIds.find((el) => el === folderId)) {
          //     reject("At least folder " + folderId + " has not been updated.");
          //     break;
          //   }
          // }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  async moveFolders(folderIds: Array<string>, newParentId: string): Promise<MoveFoldersResponse> {
    return new Promise((resolve, reject) => {
      const docGUID: string = SharedService.generateGuid();
      const request = new MoveFoldersRequest();
      request.setRequestId(docGUID);
      request.setIdsList(folderIds);
      request.setParentId(newParentId);
      this.sharedService
        .retryPromise(
          () => this.storageService.moveFolders(request, this._authMetadata),
          "moveFolders()",
          { folderIds: folderIds, newParentId: newParentId }
        )
        .then((response: MoveFoldersResponse) => {
          const updatedIds = response.getIdsList();
          for (let folderId of folderIds) {
            if (!updatedIds.find((el) => el === folderId)) {
              reject("At least folder " + folderId + " has not been moved.");
              break;
            }
          }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  async deleteFolders(
    folderIds: Array<string>,
    permanent: boolean = false
  ): Promise<DeleteFoldersResponse> {
    return new Promise((resolve, reject) => {
      const docGUID: string = SharedService.generateGuid();
      const request = new DeleteFoldersRequest();
      request.setRequestId(docGUID);
      request.setIdsList(folderIds);
      request.setPermanent(permanent);
      this.sharedService
        .retryPromise(
          () => this.storageService.deleteFolders(request, this._authMetadata),
          "deleteFolders()",
          { folderIds: folderIds, permanent: permanent }
        )
        .then((response: DeleteFoldersResponse) => {
          //TODO: Check temporarily disabled due to a bug in ICWS
          // console.log("DELETE RESPONSE: ", folderIds, response.getIdsList(), response);
          // const deletedIds = response.getIdsList();
          // for (let folderId of folderIds) {
          //   if (!deletedIds.find((el) => el === folderId)) {
          //     reject("At least folder " + folderId + " has not been deleted.");
          //     break;
          //   }
          // }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  //
  // DOCUMENT
  //
  async createDocument(
    parentId: string,
    metadata: DocumentMetadata
  ): Promise<CreateDocumentResponse> {
    const docGUID: string = SharedService.generateGuid();
    const request = new CreateDocumentRequest();
    request.setRequestId(docGUID);
    request.setParentId(parentId);
    const preparedMetadata = JSON.parse(JSON.stringify(metadata)); // workaround to prevent error in calling "fromJavaScript"
    request.setMetadata(google_protobuf_struct_pb.Struct.fromJavaScript(preparedMetadata));
    return this.sharedService.retryPromise(
      () => this.storageService.createDocument(request, this._authMetadata),
      "createDocument()",
      { parentId: parentId, metadata: metadata }
    );
  }

  getDocument(
    documentId: string,
    imageVariantType: ImageVariantType = null,
    includeScans: boolean = false,
    includeDeleted: boolean = false
  ): Promise<GetDocumentResponse> {
    const docGUID: string = SharedService.generateGuid();
    const request = new GetDocumentRequest();
    request.setRequestId(docGUID);
    request.setId(documentId);
    request.setVariant(imageVariantType);
    request.setIncludeScans(includeScans);
    request.setAllowDeleted(includeDeleted);
    return this.sharedService.retryPromise(
      () => this.storageService.getDocument(request, this._authMetadata),
      "getDocument()",
      {
        documentId: documentId,
        imageVariantType: imageVariantType,
        includeScans: includeScans,
        includeDeleted: includeDeleted,
      }
    );
  }

  async updateDocuments(
    documentIds: string[],
    metadata: DocumentMetadata,
    strategy: UpdateStrategyType = UpdateStrategyType.UPDATE_STRATEGY_REPLACE
  ): Promise<UpdateDocumentsResponse> {
    return new Promise((resolve, reject) => {
      const docGUID: string = SharedService.generateGuid();
      const request = new UpdateDocumentsRequest();
      request.setRequestId(docGUID);
      request.setIdsList(documentIds);
      request.setStrategy(strategy);
      const preparedMetadata = JSON.parse(JSON.stringify(metadata)); // workaround to prevent error in calling "fromJavaScript"
      request.setMetadata(google_protobuf_struct_pb.Struct.fromJavaScript(preparedMetadata));
      this.sharedService
        .retryPromise(
          () => this.storageService.updateDocuments(request, this._authMetadata),
          "updateDocuments()",
          { documentIds: documentIds, metadata: metadata, strategy: strategy }
        )
        .then((response: UpdateDocumentsResponse) => {
          // TODO: Check for returned IDs temporarily disabled. IDs are missing in the list even if everything is ok but nothing has changed
          // const updatedIds = response.getIdsList();
          // for (let documentId of documentIds) {
          //   if (!updatedIds.find((el) => el === documentId)) {
          //     reject(
          //       "At least document " + documentId + " has not been updated."
          //     );
          //     break;
          //   }
          // }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  async moveDocuments(
    documentIds: Array<string>,
    newParentId: string
  ): Promise<MoveDocumentsResponse> {
    return new Promise((resolve, reject) => {
      const docGUID: string = SharedService.generateGuid();
      const request = new MoveDocumentsRequest();
      request.setRequestId(docGUID);
      request.setIdsList(documentIds);
      request.setParentId(newParentId);
      this.sharedService
        .retryPromise(
          () => this.storageService.moveDocuments(request, this._authMetadata),
          "moveDocuments()",
          { documentIds: documentIds, newParentId: newParentId }
        )
        .then((response: MoveDocumentsResponse) => {
          const updatedIds = response.getIdsList();
          for (let documentId of documentIds) {
            if (!updatedIds.find((el) => el === documentId)) {
              reject("At least document " + documentId + " has not been moved.");
              break;
            }
          }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  async deleteDocuments(
    documentIds: Array<string>,
    permanent: boolean = false
  ): Promise<DeleteDocumentsResponse> {
    return new Promise((resolve, reject) => {
      const docGUID: string = SharedService.generateGuid();
      const request = new DeleteDocumentsRequest();
      request.setRequestId(docGUID);
      request.setIdsList(documentIds);
      request.setPermanent(permanent);
      this.sharedService
        .retryPromise(
          () => this.storageService.deleteDocuments(request, this._authMetadata),
          "deleteDocuments()",
          { documentIds: documentIds, permanent: permanent }
        )
        .then((response: DeleteDocumentsResponse) => {
          //TODO: Check temporarily disabled due to a bug in ICWS
          // console.log("DELETE RESPONSE: ", documentIds, response.getIdsList(), response);
          // const deletedIds = response.getIdsList();
          // for (let documentId of documentIds) {
          //   if (!deletedIds.find((el) => el === documentId)) {
          //     reject("At least document " + documentId + " has not been deleted.");
          //     break;
          //   }
          // }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  //
  // PAGE
  //
  async addPageToDocument(
    documentId: string,
    content: Uint8Array,
    metadata: PageMetadata,
    contentType: string = null
  ): Promise<AddScanToDocumentResponse> {
    const docGUID: string = SharedService.generateGuid();
    const request = new AddScanToDocumentRequest();
    request.setRequestId(docGUID);
    request.setDocumentId(documentId);
    request.setContent(content);
    request.setSha256(SharedService.calculateSHA256Hash(content));
    const preparedMetadata = JSON.parse(JSON.stringify(metadata)); // workaround to prevent error in calling "fromJavaScript"
    request.setMetadata(google_protobuf_struct_pb.Struct.fromJavaScript(preparedMetadata));
    if (contentType != null) request.setContentType(contentType);
    return new Promise((resolve, reject) => {
      this.sharedService
        .retryPromise(
          () => this.storageService.addScanToDocument(request, this._authMetadata),
          "addScanToDocument()",
          { documentId: documentId, metadata: metadata, contentType: contentType }
        )
        .then((response) => {
          this.requestUserStorageUpdate();
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  getPage(pageId: string, imageVariant: ImageVariantType): Promise<GetScanResponse> {
    const docGUID: string = SharedService.generateGuid();
    const request = new GetScanRequest();
    request.setRequestId(docGUID);
    request.setId(pageId);
    request.setVariant(imageVariant);
    return this.sharedService.retryPromise(
      () => this.storageService.getScan(request, this._authMetadata),
      "getScan()",
      { pageId, imageVariant }
    );
  }

  async updatePages(
    pageIds: string[],
    metadata: PageMetadata,
    strategy: UpdateStrategyType = UpdateStrategyType.UPDATE_STRATEGY_REPLACE
  ): Promise<UpdateScansResponse> {
    return new Promise((resolve, reject) => {
      const docGUID: string = SharedService.generateGuid();
      const request = new UpdateScansRequest();
      request.setRequestId(docGUID);
      request.setIdsList(pageIds);
      request.setStrategy(strategy);
      const preparedMetadata = JSON.parse(JSON.stringify(metadata)); // workaround to prevent error in calling "fromJavaScript"
      request.setMetadata(google_protobuf_struct_pb.Struct.fromJavaScript(preparedMetadata));
      this.sharedService
        .retryPromise(
          () => this.storageService.updateScans(request, this._authMetadata),
          "updateScans()",
          { pageIds: pageIds, metadata: metadata, strategy: strategy }
        )
        .then((response: UpdateScansResponse) => {
          // TODO: Check for returned IDs temporarily disabled. IDs are missing in the list even if everything is ok but nothing has changed
          // const updatedIds = response.getIdsList();
          // for (let pageId of pageIds) {
          //   if (!updatedIds.find((el) => el === pageId)) {
          //     reject("At least page " + pageId + " has not been updated.");
          //     break;
          //   }
          // }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  async getPageContent(
    pageId: string,
    imageVariant: ImageVariantType,
    useCache: boolean,
    contentSource: ContentSource
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const localStorageAllowed =
        this.localStorageService.isLocalStorageAllowed(STORAGE_KEY_PAGE_CACHE);

      // If cache should be used, try to load image from cache
      if (useCache && localStorageAllowed) {
        let localPath: string = GlobalConstants.IMAGE_CACHE + "/" + imageVariant + "/" + pageId;
        Filesystem.readFile({
          directory: Directory.Cache,
          path: localPath,
        })
          .then((documentFromCache) => {
            const mimeType = SharedService.getImageMimeType(atob(documentFromCache.data));
            this.logService.debug(
              TAG,
              "Page " + pageId + " loaded from local cache (" + localPath + ").",
              documentFromCache
            );
            resolve(`data:${mimeType};base64,${documentFromCache.data}`);
          })
          .catch(async (error) => {
            this.logService.debug(
              TAG,
              "Image " + pageId + " not found in local cache (" + localPath + ")."
            );
            this.loadPageAndStoreInCache(pageId, imageVariant, true, contentSource)
              .then((storedImage) => {
                const mimeType = SharedService.getImageMimeType(atob(storedImage));
                resolve(`data:${mimeType};base64,${storedImage}`);
              })
              .catch((error) => {
                reject(error);
              });
          });
      }
      // If cache is not used or the image is not in cache, load it from ICWS
      else {
        this.loadPageAndStoreInCache(pageId, imageVariant, localStorageAllowed, contentSource)
          .then((storedImage) => {
            const mimeType = SharedService.getImageMimeType(atob(storedImage));
            resolve(`data:${mimeType};base64,${storedImage}`);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  }

  private loadPageAndStoreInCache(
    pageId: string,
    imageVariant: ImageVariantType,
    localStorageAllowed: boolean,
    contentSource: ContentSource
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      var loadFunction: any;

      switch (contentSource) {
        case ContentSource.APP_TOUR:
        case ContentSource.DEMO:
          loadFunction = this.mockDataService.getMockPageImage(pageId);
          break;
        case ContentSource.ICWS:
          loadFunction = this.getPage(pageId, imageVariant);
          break;
        default:
          reject("Unknown content source.");
      }

      loadFunction
        .then((loadedPage) => {
          this.logService.debug(TAG, "Page " + pageId + " downloaded from server.");

          const base64Image =
            contentSource !== ContentSource.ICWS
              ? loadedPage
              : SharedService.convertUint8ToBase64(<Uint8Array>loadedPage.getScan().getData());

          if (localStorageAllowed) {
            // Store image in local cache before resolve
            Filesystem.writeFile({
              path: GlobalConstants.IMAGE_CACHE + "/" + imageVariant + "/" + pageId,
              data: base64Image,
              directory: Directory.Cache,
            })
              .then(() => {
                this.logService.debug(TAG, "Page " + pageId + " stored in local cache.");
                resolve(base64Image);
              })
              .catch((error) => {
                this.logService.error(
                  TAG,
                  "Error while storing page " + pageId + " in local cache.",
                  error
                );
                reject(error);
              });
          } else {
            resolve(base64Image);
          }
        })
        .catch((error) => {
          this.logService.error(
            TAG,
            "Error while loading image " + pageId + " from server.",
            error
          );
          reject(error);
        });
    });
  }

  async removePagesFromDocument(
    documentId: string,
    pageIds: Array<string>
  ): Promise<RemoveScansFromDocumentResponse> {
    return new Promise((resolve, reject) => {
      const reqGUID: string = SharedService.generateGuid();
      const request = new RemoveScansFromDocumentRequest();
      request.setRequestId(reqGUID);
      request.setDocumentId(documentId);
      request.setIdsList(pageIds);
      this.sharedService
        .retryPromise(
          () => this.storageService.removeScansFromDocument(request, this._authMetadata),
          "removeScansFromDocument()",
          { documentId: documentId, pageIds: pageIds }
        )
        .then((response: UpdateScansResponse) => {
          this.requestUserStorageUpdate();
          const deletedIds = response.getIdsList();
          for (let pageId of pageIds) {
            if (!deletedIds.find((el) => el === pageId)) {
              reject("At least page " + pageId + " has not been removed from document.");
              break;
            }
          }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  //
  // PROCESSED SCAN
  //

  async getProcessedScan(processedScanId: string): Promise<GetProcessedScanResponse> {
    const reqGUID: string = SharedService.generateGuid();
    const request = new GetProcessedScanRequest();
    request.setRequestId(reqGUID);
    request.setId(processedScanId);
    return this.sharedService.retryPromise(
      () => this.storageService.getProcessedScan(request, this._authMetadata),
      "getProcessedScan()",
      { processedScanId: processedScanId }
    );
  }

  //
  // LICENCE INFO
  //

  async GetLicenceInfo(quotaTypes: Array<QuotaType>): Promise<GetLicenceInfoResponse> {
    const reqGUID: string = SharedService.generateGuid();
    const request = new GetLicenceInfoRequest();
    request.setRequestId(reqGUID);
    request.setQuotaTypesList(quotaTypes);
    return this.sharedService.retryPromise(
      () => this.iamService.getLicenceInfo(request, this._authMetadata),
      "getLicenceInfo()",
      { quotaTypes: quotaTypes }
    );
  }

  requestUserStorageUpdate() {
    this.userStorageSource.next();
  }

  private performUserStorageUpdate(): Promise<UserStorageInfo> {
    return new Promise((resolve) => {
      if (this.globalService.currentAppMode() === AppMode.NORMAL) {
        this.GetLicenceInfo([QuotaType.STORED_DATA_SIZE_MB_QUOTA_TYPE]).then((licences) => {
          const quotaLicence: QuotaInfo = licences
            .getQuotasList()
            .find((item) => item.getType() === QuotaType.STORED_DATA_SIZE_MB_QUOTA_TYPE);
          const quotaLimit: QuotasPerTimePeriods = quotaLicence.getLimit();
          const quotaRemaining: QuotasPerTimePeriods = quotaLicence.getRemaining();
          let updatedStorageInfo: UserStorageInfo = {
            quota: Math.round(quotaLimit.getTotal() * 1000000),
            available: Math.round(quotaRemaining.getTotal() * 1000000),
          };
          this.userStorageInfo = updatedStorageInfo;
          this.logService.info(
            TAG,
            "User's available storage capacity updated: " +
              this.userStorageInfo.available +
              " of " +
              this.userStorageInfo.quota +
              " B."
          );
          resolve(this.userStorageInfo);
        });
      } else if (
        this.globalService.currentAppMode() === AppMode.DEMO ||
        this.globalService.currentAppMode() === AppMode.ANONYMOUS_USER
      ) {
        resolve({ quota: 0, available: 0 });
      }
    });
  }

  getUserStorageInfo(): Promise<UserStorageInfo> {
    return new Promise((resolve) => {
      if (this.userStorageInfo) {
        resolve(this.userStorageInfo);
      } else {
        resolve(this.performUserStorageUpdate());
      }
    });
  }

  async cleanLocalCache(localDirectory: Directory, deleteOlderThan: number) {
    const dateNow = Date.now();
    let localDirPath: string;
    let filesDeleted: number = 0;

    for (const imageVariant in Object.values(ImageVariantType)) {
      localDirPath = GlobalConstants.IMAGE_CACHE + "/" + Number(imageVariant);
      try {
        const localDir = await Filesystem.readdir({
          directory: localDirectory,
          path: localDirPath,
        });
        for (let file of localDir.files) {
          let fileStat = await Filesystem.stat({
            directory: localDirectory,
            path: localDirPath + "/" + file,
          });
          if (dateNow - fileStat.mtime > deleteOlderThan) {
            // File in cache is too old -> delete
            Filesystem.deleteFile({
              directory: localDirectory,
              path: localDirPath + "/" + file,
            });
            filesDeleted++;
          }
        }
      } catch (err) {
        // Typically the directory does not exist
        null;
      }
    }
    this.logService.info(TAG, "Cleaning local cache (" + filesDeleted + " files deleted).");
  }
}

export interface FolderMetadata {
  name: string;
}

export interface DocumentMetadata {
  name: string;
  thumbnailPage?: string;
}

export interface PageMetadata {
  name: string;
  order: number;
  quality: number;
}

export interface IcwsDocumentImage {
  documentId: string;
  imageVariantType: ImageVariantType;
  mimeType: string;
  useCache: boolean;
}

/**
 * ICWS user storage properties.
 */
export type UserStorageInfo = {
  quota: number;
  available: number;
};
