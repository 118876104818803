import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-side-panel-content",
  templateUrl: "./side-panel-content.component.html",
  styleUrls: ["./side-panel-content.component.scss"],
})
export class SidePanelContentComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
