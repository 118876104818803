import { Injectable } from "@angular/core";
import { interval, Subject, Subscription } from "rxjs";
import { ContentSource, GlobalConstants } from "../models/common";
import {
  ContentType,
  DocumentOcrState,
  IccDocument,
  IccPage,
  IccProcessingParams,
  IccTask,
  PageOcrState,
} from "../models/icc-content.model";
import { Task } from "../proto/generated/icws_proto/icws_api_gateway/processing/base_pb";
import { StartProcessingResponse } from "../proto/generated/icws_proto/icws_api_gateway/processing/start_processing_pb";
import { CancelTaskProcessingResponse } from "../proto/generated/icws_proto/icws_api_gateway/processing/cancel_task_processing_pb";
import { ImageVariantType } from "../proto/generated/icws_proto/icws_api_gateway/storage/storage_types_pb";
import { TaskStateType } from "../proto/generated/icws_proto/icws_api_gateway/types_pb";
import { ContentLoadService, LoadMode } from "./content-load.service";
import { GlobalService } from "./global.service";
import { IcwsProcessingService } from "./icws-processing.service";
import { IcwsStorageService } from "./icws-storage.service";
import { LoggingService } from "./log.service";
import { MockDataService } from "./mock-data.service";
import { OcrService } from "./page-ocr.service";
import { SharedService } from "./shared.service";

/** @ignore */
const TAG = "ProcessingService";

@Injectable({
  providedIn: "root",
})
export class ProcessingService {
  private _docOcrStateCache: DocumentOcrStateInfo[] = [];
  private docOcrStateSubject = new Subject<string>();
  readonly docOcrStateChange = this.docOcrStateSubject.asObservable();

  private _pageOcrStateCache: PageOcrStateInfo[] = [];
  private pageOcrStateSubject = new Subject<string>();
  readonly pageOcrStateChange = this.pageOcrStateSubject.asObservable();

  private ocrInfoAutoupdate: boolean = false;
  /** Subscription for refreshing ocr state list */
  private autoUpdateSubscription: Subscription;

  constructor(
    private logService: LoggingService,
    private icwsProcessingService: IcwsProcessingService,
    private icwsStorageService: IcwsStorageService,
    private contentLoadService: ContentLoadService,
    private mockDataService: MockDataService,
    private globalService: GlobalService,
    private ocrService: OcrService
  ) {
    this.runOcrStateAutoUpdate();
  }

  startDocProcessing(
    documentId: string,
    params: IccProcessingParams
  ): Promise<StartProcessingResponse> {
    if (!params) {
      params = OcrService.getGeneralProcessingParams();
    }
    return this.icwsProcessingService.startProcessing(documentId, params);
  }

  cancelTaskProcessing(taskId: string): Promise<CancelTaskProcessingResponse> {
    return this.icwsProcessingService.cancelTaskProcessing(taskId);
  }

  async getDocumentOcrStateInfo(
    documentId: string,
    forceLoad: boolean = false
  ): Promise<DocumentOcrStateInfo> {
    const newOcrTasks = await this.getDocumentOcrTasks(documentId, forceLoad);
    const newOcrState = await this.getDocumentOCRState(documentId, forceLoad);
    this.setDocOcrInfoInMemory(documentId, newOcrState, newOcrTasks);
    if (forceLoad) {
      this.docOcrStateSubject.next(documentId);
    }
    return {
      documentId: documentId,
      ocrState: newOcrState,
      taskList: newOcrTasks,
    };
  }

  async getPageOcrStateInfo(pageId: string, forceLoad: boolean = false): Promise<PageOcrStateInfo> {
    const newOcrState = await this.getPageOCRState(pageId, forceLoad);
    // storing is inside getPageOcrState
    // this.setPageOcrInfoInMemory(pageId, newOcrState);
    if (forceLoad) {
      this.pageOcrStateSubject.next(pageId);
    }
    return {
      pageId: pageId,
      ocrState: newOcrState,
    };
  }

  private getDocumentOcrTasks(
    documentId: string,
    forceloadFromIcws: boolean = false
  ): Promise<IccTask[]> {
    return new Promise<IccTask[]>((resolve) => {
      const foundInMemory = this.getDocOcrInfoFromMemory(documentId);
      if (!forceloadFromIcws && foundInMemory !== undefined) {
        resolve(foundInMemory.taskList);
      } else {
        switch (this.globalService.currentContentSource()) {
          case ContentSource.ICWS:
            this.icwsStorageService
              .getDocument(documentId, ImageVariantType.NONE, false, false)
              .then(async (response) => {
                const docTaskList = await this.mapIcwsTaskList(response.getTasksList());
                resolve(docTaskList);
              });
            break;
          case ContentSource.DEMO:
          case ContentSource.APP_TOUR:
            resolve([this.mockDataService.getMockOcrTask(documentId)]);
            break;
        }
      }
    });
  }

  /**
   * Evaluates OCR state info for the document from all tasks related to the document.
   * @param {TaskInfo[]} tasks Array of all tasks related with pages in the document.
   * @returns {TaskStateType} OCR State for the document
   */
  private async getDocumentOCRState(
    documentId: string,
    forceloadFromIcws: boolean = false
  ): Promise<DocumentOcrState> {
    let new_counter = 0;
    let in_progress_counter = 0;
    let paused_counter = 0;
    let finished_ok_counter = 0;
    let finished_err_counter = 0;
    let aborted_counter = 0;
    let cancelled_counter = 0;

    const foundInMemory = this.getDocOcrInfoFromMemory(documentId);
    if (!forceloadFromIcws && foundInMemory !== undefined) {
      return foundInMemory.ocrState;
    } else {
      switch (this.globalService.currentContentSource()) {
        case ContentSource.ICWS:
          const document = await this.icwsStorageService.getDocument(
            documentId,
            ImageVariantType.NONE,
            false,
            false
          );
          const tasks = document.getTasksList();
          for (let task of tasks) {
            switch (task.getState()) {
              case TaskStateType.TASK_STATE_TYPE_NEW:
                new_counter++;
                break;
              case TaskStateType.TASK_STATE_TYPE_IN_PROGRESS:
                in_progress_counter++;
                break;
              case TaskStateType.TASK_STATE_TYPE_PAUSED:
                paused_counter++;
                break;
              case TaskStateType.TASK_STATE_TYPE_FINISHED_OK:
                finished_ok_counter++;
                break;
              case TaskStateType.TASK_STATE_TYPE_FINISHED_ERR:
                finished_err_counter++;
                break;
              case TaskStateType.TASK_STATE_TYPE_ABORTED:
                aborted_counter++;
                break;
              case TaskStateType.TASK_STATE_TYPE_CANCELLED:
                cancelled_counter++;
                break;
            }
          }

          if (tasks.length === 0) return DocumentOcrState.NOT_STARTED;
          if (finished_ok_counter === tasks.length) return DocumentOcrState.FINISHED_OK;
          if (in_progress_counter > 0) return DocumentOcrState.IN_PROGRESS;
          if (finished_err_counter > 0) return DocumentOcrState.FINISHED_ERR;
          return DocumentOcrState.UNKNOWN;
        case ContentSource.APP_TOUR:
        case ContentSource.DEMO:
          const iccTask = this.mockDataService.getMockOcrTask(documentId);
          return iccTask?.state == TaskStateType.TASK_STATE_TYPE_FINISHED_OK
            ? DocumentOcrState.FINISHED_OK
            : DocumentOcrState.NOT_STARTED;
        default:
          return DocumentOcrState.NOT_STARTED;
      }
    }
  }

  // private async XXXgetDocumentOCRState(
  //   documentId: string,
  //   forceloadFromIcws: boolean = false
  // ): Promise<DocumentOcrState> {
  //   let pageCount = 0;
  //   let pageUnprocessed = 0;
  //   let pageOkCount = 0;
  //   let pageErrCount = 0;
  //   let pageInProgressCount = 0;
  //   let finalState: DocumentOcrState = DocumentOcrState.UNKNOWN;

  //   const foundInMemory = this.getDocOcrInfoFromMemory(documentId);
  //   if (!forceloadFromIcws && foundInMemory !== undefined) {
  //     return foundInMemory.ocrState;
  //   } else {
  //     const document = await this.contentLoadService.getNode(
  //       documentId,
  //       LoadMode.LOAD_WHEN_NEEDED,
  //       ContentType.DOCUMENT
  //     );

  //     pageCount = document.children.length;
  //     for (let page of document.children) {
  //       const pageState = await this.getPageOCRState(page.icwsId, forceloadFromIcws);
  //       switch (pageState) {
  //         case PageOcrState.NOT_STARTED:
  //           pageUnprocessed++;
  //           break;
  //         case PageOcrState.FINISHED_OK:
  //           pageOkCount++;
  //           break;
  //         case PageOcrState.FINISHED_ERR:
  //           pageErrCount++;
  //           break;
  //         case PageOcrState.IN_PROGRESS:
  //           pageInProgressCount++;
  //           break;
  //       }
  //     }

  //     if (pageUnprocessed === pageCount) {
  //       finalState = DocumentOcrState.NOT_STARTED;
  //     } else if (pageUnprocessed > 0 && pageUnprocessed < pageCount) {
  //       finalState = DocumentOcrState.NOT_COMPLETE;
  //     } else if (pageInProgressCount > 0) {
  //       finalState = DocumentOcrState.IN_PROGRESS;
  //     } else if (pageErrCount > 0) {
  //       finalState = DocumentOcrState.FINISHED_ERR;
  //     } else if (pageOkCount === pageCount) {
  //       finalState = DocumentOcrState.FINISHED_OK;
  //     }
  //     return finalState;
  //   }
  // }

  private async getPageOCRState(
    pageId: string,
    forceloadFromIcws: boolean = false
  ): Promise<PageOcrState> {
    try {
      const foundInMemory = this.getPageOcrInfoFromMemory(pageId);
      if (!forceloadFromIcws && foundInMemory !== undefined) {
        return foundInMemory.ocrState;
      } else {
        let pageState: PageOcrState = PageOcrState.UNKNOWN;
        if (!forceloadFromIcws) {
          const page = <IccPage>(
            await this.contentLoadService.getNode(
              pageId,
              LoadMode.LOAD_WHEN_NEEDED,
              ContentType.PAGE
            )
          );
          pageState = page.pageState;
        } else {
          const icwsPage = await this.icwsStorageService.getPage(pageId, ImageVariantType.NONE);
          pageState = SharedService.getIccPageState(icwsPage.getScan().getState());
        }
        this.setPageOcrInfoInMemory(pageId, pageState);
        return pageState;
      }
    } catch (e) {
      return PageOcrState.UNKNOWN;
    }
  }

  private async mapIcwsTaskList(icwsTaskList: Task[]): Promise<IccTask[]> {
    let progressQueryList: string[] = [];
    for (let task of icwsTaskList) {
      if (task.getState() === TaskStateType.TASK_STATE_TYPE_IN_PROGRESS) {
        progressQueryList.push(task.getId());
      }
    }
    const taskProgressResponse = await this.icwsProcessingService.GetTaskProgress(
      progressQueryList
    );
    const taskProgressMap = taskProgressResponse.getTasksProgressesMap();
    return icwsTaskList.map((item) => {
      return {
        icwsId: item.getId(),
        params: this.ocrService.getProcessingParamsFromIcws(item.getParams()),
        state: item.getState(),
        progress: taskProgressMap.get(item.getId()) ? taskProgressMap.get(item.getId()) : 0,
        created_at: item.getCreatedAt().toDate(),
        finished_at: item.getFinishedAt()?.toDate(),
        pageNumStarted: item.getScansNumStarted(),
        pageNumFinished: item.getScansNumFinished()?.toJavaScript(),
      };
    });
  }

  async addDocumentToOcrStateCache(
    documentId: string,
    docOcrState: DocumentOcrState = null,
    docOcrTasks: IccTask[] = null
  ) {
    this.setDocOcrInfoInMemory(
      documentId,
      docOcrState ? docOcrState : await this.getDocumentOCRState(documentId),
      docOcrTasks ? docOcrTasks : await this.getDocumentOcrTasks(documentId)
    );
    this.docOcrStateSubject.next(documentId);
  }

  removeDocumentFromOcrStateCache(documentId: string) {
    const indexFound = this._docOcrStateCache.findIndex((e) => e.documentId === documentId);
    if (indexFound >= 0) {
      this._docOcrStateCache.splice(indexFound, 1);
    }
  }

  async SetDocumentWithPagesInProgressInOcrStateCache(documentId: string) {
    const document = <IccDocument>(
      await this.contentLoadService.getNode(
        documentId,
        LoadMode.LOAD_WHEN_NEEDED,
        ContentType.DOCUMENT
      )
    );
    this.setDocOcrInfoInMemory(documentId, DocumentOcrState.IN_PROGRESS, null);
    for (let page of document.children) {
      this.setPageOcrInfoInMemory(page.icwsId, PageOcrState.IN_PROGRESS);
    }
  }

  async addPageToOcrStateCache(pageId: string, pageOcrState: PageOcrState = null) {
    this.setPageOcrInfoInMemory(
      pageId,
      pageOcrState ? pageOcrState : await this.getPageOCRState(pageId)
    );
    this.pageOcrStateSubject.next(pageId);
  }

  removePageFromOcrStateCache(pageId: string) {
    const indexFound = this._pageOcrStateCache.findIndex((e) => e.pageId === pageId);
    if (indexFound >= 0) {
      this._pageOcrStateCache.splice(indexFound, 1);
    }
  }

  isDocStateTemporary(docState: DocumentOcrState): boolean {
    if (docState === DocumentOcrState.IN_PROGRESS /* || docState === DocumentOcrState.UNKNOWN */) {
      return true;
    } else {
      return false;
    }
  }

  isPageStateTemporary(pageState: PageOcrState): boolean {
    if (pageState === PageOcrState.IN_PROGRESS || pageState === PageOcrState.UNKNOWN) {
      return true;
    } else {
      return false;
    }
  }

  isTaskInTemporaryState(tasks: IccTask[]): boolean {
    const tasksInProgress = tasks.findIndex((e) => {
      return e.state === TaskStateType.TASK_STATE_TYPE_IN_PROGRESS;
    });
    const tasksNew = tasks.findIndex((e) => {
      return e.state === TaskStateType.TASK_STATE_TYPE_NEW;
    });
    const tasksPaused = tasks.findIndex((e) => {
      return e.state === TaskStateType.TASK_STATE_TYPE_PAUSED;
    });
    if (tasksInProgress >= 0 || tasksNew >= 0 || tasksPaused >= 0) {
      return true;
    } else {
      return false;
    }
  }

  private getDocOcrInfoFromMemory(documentId: string): DocumentOcrStateInfo {
    return this._docOcrStateCache.find((e) => e.documentId === documentId);
  }

  private setDocOcrInfoInMemory(
    documentId: string,
    ocrState: DocumentOcrState,
    ocrTasks: IccTask[]
  ): DocumentOcrStateInfo {
    const indexFound = this._docOcrStateCache.findIndex((e) => e.documentId === documentId);
    if (indexFound >= 0) {
      // record exists -> update
      this._docOcrStateCache[indexFound].taskList = ocrTasks;
      this._docOcrStateCache[indexFound].ocrState = ocrState;
      return this._docOcrStateCache[indexFound];
    } else {
      // record doesn't exist -> insert
      this._docOcrStateCache.push({
        documentId: documentId,
        ocrState: ocrState,
        taskList: ocrTasks,
      });
      return this._docOcrStateCache[this._docOcrStateCache.length - 1];
    }
  }

  private getPageOcrInfoFromMemory(pageId: string): PageOcrStateInfo {
    return this._pageOcrStateCache.find((e) => e.pageId === pageId);
  }

  private setPageOcrInfoInMemory(pageId: string, ocrState: PageOcrState): PageOcrStateInfo {
    const indexFound = this._pageOcrStateCache.findIndex((e) => e.pageId === pageId);
    if (indexFound >= 0) {
      // record exists -> update
      this._pageOcrStateCache[indexFound].ocrState = ocrState;
      return this._pageOcrStateCache[indexFound];
    } else {
      // record doesn't exist -> insert
      this._pageOcrStateCache.push({
        pageId: pageId,
        ocrState: ocrState,
      });
      return this._pageOcrStateCache[this._pageOcrStateCache.length - 1];
    }
  }

  // startAutoUpdate2() {
  //   this.ocrInfoAutoupdate = true;
  //   this.runOcrStateAutoUpdate();
  // }

  // stopAutoUpdate2() {
  //   this.ocrInfoAutoupdate = false;
  // }

  private runOcrStateAutoUpdate() {
    if (!this.autoUpdateSubscription) {
      this.autoUpdateSubscription = interval(GlobalConstants.OCR_TASK_STATUS_REFRESH).subscribe(
        async () => {
          // reload state of pages which are in temporary state
          for (let pageRecord of this._pageOcrStateCache) {
            if (this.isPageStateTemporary(pageRecord.ocrState)) {
              this.logService.debug(
                TAG,
                'Autocheck OCR state of page "' + pageRecord.pageId + '".'
              );
              await this.updatePageOcrState(pageRecord.pageId);
              this.pageOcrStateSubject.next(pageRecord.pageId);
            }
          }
          // reload state of tasks which are in temporary state
          for (let docRecord of this._docOcrStateCache) {
            if (this.isDocStateTemporary(docRecord.ocrState)) {
              this.logService.debug(
                TAG,
                'Autocheck OCR state of document "' + docRecord.documentId + '".'
              );
              await this.updateDocumentOcrState(docRecord.documentId);
              this.docOcrStateSubject.next(docRecord.documentId);
            }
          }
        }
      );
    }
  }

  private async updateDocumentOcrState(documentId: string) {
    const newOcrTasks = await this.getDocumentOcrTasks(documentId, true);
    // No need to force load for doc ocr state, because all pages state have been updated
    // in previous call of updatePageOcrState in runOcrStateAutoUpdate
    const newOcrState = await this.getDocumentOCRState(documentId, true);
    this.setDocOcrInfoInMemory(documentId, newOcrState, newOcrTasks);
  }

  private async updatePageOcrState(pageId: string) {
    const newOcrState = await this.getPageOCRState(pageId, true);
    const state = this.setPageOcrInfoInMemory(pageId, newOcrState);
  }
}

export interface DocumentOcrStateInfo {
  documentId: string;
  ocrState: DocumentOcrState;
  taskList: IccTask[];
}

export interface PageOcrStateInfo {
  pageId: string;
  ocrState: PageOcrState;
}
