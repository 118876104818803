/**
 * Data structures describing data stored on a server (ICWS - InkCapture WebServer).
 * The basic data structure is folder/document/page.
 * Example of data hierarchy:
 *  root
 *  ├── folder 1
 *  │   ├── document 1
 *  │   │    ├── page 1
 *  │   │    └── page 2
 *  │   └── document 2
 *  └── folder 2
 */

import { Permission } from "../proto/generated/icws_proto/icws_api_gateway/auth/base_pb";
import {
  ProcessedScanStateType,
  TaskStateType,
} from "../proto/generated/icws_proto/icws_api_gateway/types_pb";

/**
 * A general type representing a single node in hierarchy of nodes.
 */
export type IccContent = IccFolder | IccDocument | IccPage;

/**
 * Description of one folder.
 */
export class IccFolder {
  /** ID in ICWS */
  icwsId: string;
  /** Name of the folder */
  name: string;
  /** ID of the parent folder. */
  parentId: string;
  /** List of documents or folders belonging to this folder */
  children: Array<IccFolder | IccDocument>;
  /** Indicates if the node needs to be refreshed from server */
  valid?: boolean = false;

  public get type() {
    return ContentType.FOLDER;
  }

  constructor(data: Partial<IccFolder>) {
    Object.assign(this, data);
  }
}

/**
 * Description of one document.
 */
export class IccDocument {
  /** ID in ICWS */
  icwsId: string;
  /** Name of the document */
  name: string;
  /** ID of the parent folder. */
  parentId: string;
  /** List of pages belonging to this document */
  children: IccPage[];
  /** Indicates if the node needs to be refreshed from server */
  valid?: boolean = false;
  /** List of activites allowed to current user */
  my_allowed_activites: string[];
  /**  List of all permissions related to the document */
  permissions: Permission[];
  /** Id of the page used as a document thumbnail */
  thumbnailPageId: string;
  /** Number of pages in document */
  scans_num: number;
  /** State of the document in terms of OCR */
  documentState: DocumentOcrState;

  public get type() {
    return ContentType.DOCUMENT;
  }

  constructor(data: Partial<IccDocument>) {
    Object.assign(this, data);
  }
}

/**
 * Description of one page of the document.
 */
export class IccPage {
  /** ID in ICWS */
  icwsId: string;
  /** Name of the page */
  name: string;
  /** Oder of the page in document */
  order: number;
  /** Date the page was created */
  createdTime: Date;
  /** ID of the parent document. */
  parentId: string;
  /** Image quality in terms of OCR processing (0.0-1.0, 1 is the best). */
  quality: number;
  /** State of the page in terms of OCR */
  pageState: PageOcrState;
  /** Indicates if the node needs to be refreshed from server */
  valid?: boolean = false;
  /** List of processed pages. */
  processedPages: IccProcessedPage[];

  public get children() {
    return [];
  }

  public get type() {
    return ContentType.PAGE;
  }

  constructor(data: Partial<IccPage>) {
    Object.assign(this, data);
  }
}

export interface IccProcessedPage {
  icwsId: string;
  created_at: Date;
  content: StructuredContent;
  state: ProcessedScanStateType;
  task: IccTask;
}

export interface IccTask {
  icwsId: string;
  params: IccProcessingParams;
  state: TaskStateType;
  progress: number;
  created_at: Date;
  finished_at: Date;
  pageNumStarted: number;
  pageNumFinished: {
    [key: string]: any;
  };
}

export interface IccProcessingParams {
  id: string;
  hash: string;
  det: any;
  seg: any;
  ocr: any;
}

/**
 * A structure for describing the content of a page as an array of lines, which further consist of array of words.
 * Lines and words have their own attributes (e.g. coordinates on the page).
 */
export interface StructuredContent {
  /** Lines of text with additional attributes. */
  lines: StructuredLine[];
}

/**
 * Single block of text on page.
 */
export interface StructuredBlock {
  /** Id of the block, used for referencing the corrections. */
  guid: string;
  /** Bounding box surrounding the block */
  boundingBox: RectangleByPoints;
  /** List of lines belonging to this block */
  lines: StructuredLine[];
}

/**
 * Single line of text on page.
 */
export interface StructuredLine {
  /** Id of the line, used for referencing the corrections. */
  guid: string;
  /** Bounding box surrounding the line */
  boundingBox: RectangleByPoints;
  /** List of words belonging to this line */
  words: StructuredWord[];
}

/**
 * Single word in [StructuredLine]{@link StructuredLine}.
 */
export interface StructuredWord {
  /** Id of the word, used for referencing the corrections. */
  guid: string;
  /** Bounding box surrounding the word */
  boundingBox: RectangleByPoints;
  /** Word content (text itself) */
  content: string;
  /** Level of confidence in the accuracy of OCR recognition of each character in the string.
   *  List of numbers, each between the values 0 (uncertain) and 9 (certain) for each character;
   *  e.g. CC="0001" for CONTENT="TEXT";
   */
  charsCertainty: number[];
  /** Level of confidence in the accuracy of the OCR output of the entire string-word (word confidence).
   * Value between 0 (uncertain) and 1 (confident); e.g. WC="0.99";
   */
  wordCertainty: number;
  splitType: WordSplitType;
  unsplittedWord: string;
}

export interface StructuredContentCorrection {
  /** Corrected lines of text */
  lines: StructuredLineCorrection[];
}

export interface StructuredLineCorrection {
  /** Guid of the line to which the correction applies */
  correctedLineGuid: string;
  /** Bounding box surrounding the line */
  boundingBox: RectangleByPoints;
  /** List of words belonging to this line */
  words: StructuredWordCorrection[];
  /** Correction type */
  type: CorrectionType;
}

export interface StructuredWordCorrection {
  /** Guid of the word to which the correction applies */
  correctedWordGuid: string;
  /** Bounding box surrounding the word */
  boundingBox: RectangleByPoints;
  /** Word content (text itself) */
  content: string;
  /** Correction type */
  type: CorrectionType;
}

/**
 * Data structure for storing coordinates of a rectangle through a single point, length and height.
 */
export interface RectangleBySize {
  /** X coordinate of the upper left corner. */
  x: number;
  /** Y coordinate of the upper left corner. */
  y: number;
  /** Width of the rectangle. */
  width: number;
  /** Height of the rectangle. */
  height: number;
}

/**
 * Data structure for storing coordinates of a rectangle defined by 4 corners.
 */
export interface RectangleByPoints {
  /** 1st corner (usually top left) */
  a: Point;
  /** 2nd corner (usually top right) */
  b: Point;
  /** 3rd corner (usually botom right) */
  c: Point;
  /** 4th corner (usually bottom left) */
  d: Point;
}

export interface Point {
  x: number;
  y: number;
}

export enum WordSplitType {
  UNKNOWN = 0,
  NOT_SPLITTED = 1,
  FIRST_PART = 2,
  SECOND_PART = 3,
}

/**
 * Possible types of content in ICWS storage.
 */
export enum ContentType {
  FOLDER = 0,
  DOCUMENT = 1,
  PAGE = 2,
}

export enum CorrectionType {
  NONE = 0,
  INSERT = 1,
  UPDATE = 2,
  DELETE = 3,
}

export enum DocumentOcrState {
  UNKNOWN = 0,
  NOT_STARTED = 1,
  IN_PROGRESS = 2,
  FINISHED_OK = 3,
  FINISHED_ERR = 4,
  NOT_COMPLETE = 5,
}

export enum PageOcrState {
  UNKNOWN = 0,
  NOT_STARTED = 1,
  IN_PROGRESS = 2,
  FINISHED_OK = 3,
  FINISHED_ERR = 4,
}

/**
 * Symbolic name for the root node in hierarchy. It represents the json path used for translation.
 */
export const ROOT_NODE_NAME = "contentService.root";
