import { Injectable } from "@angular/core";
import { ContentSource, GlobalConstants } from "../models/common";
import {
  CorrectionType,
  IccProcessingParams,
  IccPage,
  IccProcessedPage,
  Point,
  RectangleByPoints,
  StructuredBlock,
  StructuredContent,
  StructuredLine,
  StructuredLineCorrection,
  StructuredWord,
  StructuredWordCorrection,
  WordSplitType,
  IccTask,
} from "../models/icc-content.model";
import {
  ProcessingParams,
  Task,
} from "../proto/generated/icws_proto/icws_api_gateway/processing/base_pb";
import {
  Block,
  Line,
  Point as IcwsPoint,
  Position,
  ProcessedScan,
  Word,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/base_pb";
import {
  ImageVariantType,
  WordSplitTypeType,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/storage_types_pb";
import { ProcessedScanStateType } from "../proto/generated/icws_proto/icws_api_gateway/types_pb";
import { GlobalService } from "./global.service";
import { IcwsStorageService } from "./icws-storage.service";
import { LoggingService } from "./log.service";
import { MockDataService } from "./mock-data.service";
import { SharedService } from "./shared.service";

/** @ignore */
const TAG = "PageOcrService";

@Injectable({
  providedIn: "root",
})
export class OcrService {
  private static generalProcessingParams: IccProcessingParams = {
    id: GlobalConstants.OCR_PROCESSING_PARAMS_GENERAL_ID,
    hash: null,
    det: { model: GlobalConstants.OCR_PROCESSING_PARAMS_GENERAL_ID },
    seg: {
      model: GlobalConstants.OCR_PROCESSING_PARAMS_GENERAL_ID,
    },
    ocr: {
      model: GlobalConstants.OCR_PROCESSING_PARAMS_GENERAL_ID,
    },
  };

  constructor(
    private globalService: GlobalService,
    private icwsStorage: IcwsStorageService,
    private mockDataService: MockDataService,
    private logService: LoggingService
  ) {}

  loadProcessedPages(page: IccPage, forceLoad: boolean = false): Promise<IccProcessedPage[]> {
    return new Promise((resolve) => {
      if (forceLoad || !page.processedPages || page.processedPages?.length == 0) {
        page.processedPages = [];
        if (this.globalService.currentContentSource() == ContentSource.ICWS) {
          // get processed page ids
          this.icwsStorage.getPage(page.icwsId, ImageVariantType.NONE).then(async (icwsPage) => {
            // get processed page for each id
            for (let procPageId of icwsPage.getProcessedScansIdsList()) {
              this.logService.debug(
                TAG,
                "Loading processedPage " +
                  procPageId +
                  " for page '" +
                  page.name +
                  "' (" +
                  page.icwsId +
                  ")"
              );
              const processedScanResp = await this.icwsStorage.getProcessedScan(procPageId);
              const iccProcPage: IccProcessedPage = this.createIccProcessedPage(
                processedScanResp.getProcessedScan(),
                processedScanResp.getTask()
              );
              const procPageFound = page.processedPages?.findIndex(
                (item) => item.icwsId === iccProcPage.icwsId
              );
              if (procPageFound === -1) {
                page.processedPages.push(iccProcPage);
              }
            }
            resolve(page.processedPages);
          });
        } else {
          this.loadMockProcessedPages(page).then((procPages) => {
            page.processedPages = procPages;
            resolve(page.processedPages);
          });
        }
      } else {
        resolve(page.processedPages);
      }
    });
  }

  private loadMockProcessedPages(page: IccPage): Promise<IccProcessedPage[]> {
    let procPage: IccProcessedPage;
    let procPageTask: IccTask;
    // let content: { plainText: string; structuredText: StructuredContent };
    return new Promise((resolve) => {
      procPageTask = this.mockDataService.getMockOcrTask(page.icwsId);
      this.mockDataService.getMockPageText(page).then((content) => {
        procPage = {
          icwsId: page.icwsId + "_proc",
          created_at: new Date(2023, 0, 20, 11, 0),
          content: content.structuredText,
          state: ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_FINISHED_OK,
          task: procPageTask,
        };
        resolve([procPage]);
      });
    });
  }

  static getGeneralProcessingParams(): IccProcessingParams {
    return SharedService.deepCopy(OcrService.generalProcessingParams);
  }

  getProcessingParams(): IccProcessingParams[] {
    return [
      OcrService.getGeneralProcessingParams(),
      {
        id: "kurrent",
        hash: null,
        det: { model: "kurrent" },
        seg: { model: "kurrent" },
        ocr: { model: "kurrent" },
      },
    ];
  }

  /**
   * Find index of processed page which match to required params.
   * @param {IccPage} page Page in which to search for processed pages.
   * @param {IccProcessingParams} page Required processed page parameters.
   * @param {boolean} exactMatch If <code>true</code>, exact parameters match is valid only. If <code>false</code>, try to find the closest match.
   * In this case the method always return value >= 0 if there exists any processed page.
   */
  findContentByParams(
    page: IccPage,
    params: IccProcessingParams,
    exactMatch: boolean = true
  ): number {
    if (!params && exactMatch) return -1;
    for (let i = 0; i < page.processedPages.length; i++) {
      const pageParams: IccProcessingParams = page.processedPages[i].task.params;
      if (this.equalProcessingParams(pageParams, params)) {
        return i;
      }
    }
    if (!exactMatch && page.processedPages?.length > 0) {
      //TODO: "similar match" is not fully implemented yet
      return 0;
    } else {
      return -1;
    }
  }

  equalProcessingParams(par1: IccProcessingParams, par2: IccProcessingParams): boolean {
    if (
      par1.det.model == par2.det.model &&
      par1.seg.model == par2.seg.model &&
      par1.ocr.model == par2.ocr.model
    ) {
      return true;
    } else {
      return false;
    }
  }

  private createIccProcessedPage(processedScan: ProcessedScan, task: Task): IccProcessedPage {
    // create basic IccProcessedPage object
    const procPage: IccProcessedPage = {
      icwsId: processedScan.getId(),
      created_at: processedScan.getCreatedAt().toDate(),
      content: null,
      state: processedScan.getState(),
      task: null,
    };
    // create IccTaskInfo
    procPage.task = this.getTaskInfoFromIcwsTask(task);
    // create structured content
    procPage.content = this.getStructuredTextFromProcessedPage(processedScan);
    return procPage;
  }

  private getTaskInfoFromIcwsTask(task: Task): IccTask {
    const procTask: IccTask = {
      icwsId: task.getId(),
      params: this.getProcessingParamsFromIcws(task.getParams()),
      progress: 1,
      state: task.getState(),
      created_at: task.getCreatedAt()?.toDate(),
      finished_at: task.getFinishedAt()?.toDate(),
      pageNumStarted: task.getScansNumStarted(),
      pageNumFinished: task.getScansNumFinished(),
    };
    return procTask;
  }

  getProcessingParamsFromIcws(icwsParams: ProcessingParams): IccProcessingParams {
    if (icwsParams) {
      let iccParams: IccProcessingParams = {
        id: "custom",
        hash: icwsParams.getHashSha256(),
        det: icwsParams.getDet().toJavaScript(),
        seg: icwsParams.getSeg().toJavaScript(),
        ocr: icwsParams.getOcr().toJavaScript(),
      };
      const namedParamsFound = this.getProcessingParams().find((item) =>
        this.equalProcessingParams(iccParams, item)
      );
      if (namedParamsFound) {
        iccParams.id = namedParamsFound.id;
      }
      return iccParams;
    } else {
      const iccUnknownParams = SharedService.deepCopy(OcrService.generalProcessingParams);
      iccUnknownParams.id = "unknown";
      return iccUnknownParams;
    }
  }

  private getStructuredTextFromProcessedPage(processedPage: ProcessedScan): StructuredContent {
    let content: StructuredContent = { lines: [] };
    let structuredBlock: StructuredBlock;
    for (let block of processedPage.getBlocksList()) {
      structuredBlock = this.createNewBlock(processedPage.getBlocksList()[0]);
      for (let line of block.getLinesList()) {
        const linesLenght = content.lines.push(this.createNewLine(line, structuredBlock));
        if (line.getWordsList().length > 0) {
          for (let word of line.getWordsList()) {
            content.lines[linesLenght - 1].words.push(this.createNewWord(word, structuredBlock));
          }
        } else {
          content.lines[linesLenght - 1].words.push(
            this.createDymmyWordForLine(line, structuredBlock)
          );
        }
      }
    }
    return content;
  }

  private createNewBlock(block: Block): StructuredBlock {
    return {
      guid: block.getId(),
      boundingBox: {
        a: this.getIcwsPoint(block.getPosition().getTopLeft()),
        b: this.getIcwsPoint(block.getPosition().getTopRight()),
        c: this.getIcwsPoint(block.getPosition().getBottomRight()),
        d: this.getIcwsPoint(block.getPosition().getBottomLeft()),
      },
      lines: [],
    };
  }

  private createNewLine(line: Line, parentStructuredBlock: StructuredBlock): StructuredLine {
    return {
      guid: line.getId(),
      boundingBox: this.absoluteRectangleFromRelative(
        parentStructuredBlock.boundingBox.a,
        this.getIcwsRectangle(line.getPosition())
      ),
      words: [],
    };
  }

  private createNewWord(word: Word, parentStructuredBlock: StructuredBlock): StructuredWord {
    let iccSplitType: WordSplitType = WordSplitType.UNKNOWN;
    switch (word.getSplitType()) {
      case WordSplitTypeType.NOT_SPLIT:
        iccSplitType = WordSplitType.NOT_SPLITTED;
        break;
      case WordSplitTypeType.FIRST_PART_SPLIT:
        iccSplitType = WordSplitType.FIRST_PART;
        break;
      case WordSplitTypeType.SECOND_PART_SPLIT:
        iccSplitType = WordSplitType.SECOND_PART;
        break;
    }
    return {
      guid: word.getId(),
      boundingBox: this.absoluteRectangleFromRelative(
        parentStructuredBlock.boundingBox.a,
        this.getIcwsRectangle(word.getPosition())
      ),
      content: word.getText(),
      charsCertainty: word.getConfidenceCharsList(),
      wordCertainty: word.getConfidence(),
      splitType: iccSplitType,
      unsplittedWord: null,
    };
  }

  private createDymmyWordForLine(
    line: Line,
    parentStructuredBlock: StructuredBlock
  ): StructuredWord {
    return {
      guid: SharedService.generateGuid(),
      boundingBox: this.absoluteRectangleFromRelative(
        parentStructuredBlock.boundingBox.a,
        this.getIcwsRectangle(line.getPosition())
      ),
      content: "?",
      charsCertainty: [0],
      wordCertainty: 0,
      splitType: WordSplitType.NOT_SPLITTED,
      unsplittedWord: null,
    };
  }

  private absolutePointFromRelative(zeroPoint: Point, relativePoint: Point): Point {
    return {
      x: zeroPoint.x + relativePoint.x,
      y: zeroPoint.y + relativePoint.y,
    };
  }

  private absoluteRectangleFromRelative(
    zeroPoint: Point,
    relativeRectangle: RectangleByPoints
  ): RectangleByPoints {
    const newA: Point = this.absolutePointFromRelative(zeroPoint, relativeRectangle.a);
    const newB: Point = this.absolutePointFromRelative(zeroPoint, relativeRectangle.b);
    const newC: Point = this.absolutePointFromRelative(zeroPoint, relativeRectangle.c);
    const newD: Point = this.absolutePointFromRelative(zeroPoint, relativeRectangle.d);
    return {
      a: newA,
      b: newB,
      c: newC,
      d: newD,
    };
  }

  private getIcwsPoint(icwsPoint: IcwsPoint): Point {
    return {
      x: icwsPoint.getX(),
      y: icwsPoint.getY(),
    };
  }

  private getIcwsRectangle(icwsRectangle: Position): RectangleByPoints {
    return {
      a: this.getIcwsPoint(icwsRectangle.getTopLeft()),
      b: this.getIcwsPoint(icwsRectangle.getTopRight()),
      c: this.getIcwsPoint(icwsRectangle.getBottomRight()),
      d: this.getIcwsPoint(icwsRectangle.getBottomLeft()),
    };
  }

  // *******************************************************
  // *** GENERATE DIFF BETWEEN ORIGINAL AND MODIFIED OCR ***
  // *******************************************************

  generateDiffStructuredContent(original: StructuredContent, modified: StructuredContent) {
    let modifCopy: StructuredContent = SharedService.deepCopy(modified);
    let diff: StructuredLineCorrection[] = [];
    console.log("ORIGINAL/MODIFIED CONTENT:", original, modified);
    for (let origLine of original.lines) {
      const modifLineIndex = modifCopy.lines.findIndex((item) => item.guid === origLine.guid);
      if (modifLineIndex !== -1) {
        // the line is in the original and modified content
        let diffLine = this.evaluateDiffInLine(origLine, modifCopy.lines[modifLineIndex]);
        const diffWords = this.generateDiffWords(
          origLine.words,
          modifCopy.lines[modifLineIndex].words
        );
        diffLine.words = diffWords;

        if (
          diffWords.length > 0 ||
          (diffWords.length === 0 && diffLine.type === CorrectionType.UPDATE)
        ) {
          // The line contains some changed words
          // or the words are unchanged but the line attributes have been changed.
          diff.push(diffLine);
        }
        modifCopy.lines.splice(modifLineIndex, 1);
      } else {
        // line not found
        diff.push({
          correctedLineGuid: origLine.guid,
          boundingBox: null,
          words: null,
          type: CorrectionType.DELETE,
        });
      }
    }

    if (modifCopy.lines.length > 0) {
      // new lines to add
      for (let newLine of modifCopy.lines) {
        const addWords = this.generateDiffWords([], newLine.words);
        let addLine: StructuredLineCorrection = {
          correctedLineGuid: newLine.guid,
          boundingBox: newLine.boundingBox,
          words: addWords,
          type: CorrectionType.INSERT,
        };
        diff.push(addLine);
      }
    }
    modifCopy.lines = [];
    console.log("FINAL DIFF:", JSON.stringify(diff, null, 4));
  }

  private generateDiffWords(
    original: StructuredWord[],
    modified: StructuredWord[]
  ): StructuredWordCorrection[] {
    let diffWords: StructuredWordCorrection[] = [];
    for (let origWord of original) {
      const modifWordIndex = modified.findIndex((item) => item.guid === origWord.guid);
      if (modifWordIndex !== -1) {
        // the word is in the original and modified content
        const wordToUpdate = this.evaluateDiffInWord(origWord, modified[modifWordIndex]);
        if (wordToUpdate && wordToUpdate.type !== CorrectionType.NONE) {
          // word found and changed
          diffWords.push(wordToUpdate);
        }
        // Remove the updated word from the list so that at the end of the loop,
        // only the newly added words remain in the list.
        modified.splice(modifWordIndex, 1);
      } else {
        // word not found
        diffWords.push({
          correctedWordGuid: origWord.guid,
          boundingBox: null,
          content: null,
          type: CorrectionType.DELETE,
        });
      }
    }

    if (modified.length > 0) {
      // new words to add
      for (let newWord of modified) {
        diffWords.push({
          correctedWordGuid: newWord.guid,
          boundingBox: newWord.boundingBox,
          content: newWord.content,
          type: CorrectionType.INSERT,
        });
      }
    }
    modified = [];
    return diffWords;
  }

  private evaluateDiffInLine(
    original: StructuredLine,
    modified: StructuredLine
  ): StructuredLineCorrection {
    if (!this.sameRectangles(original.boundingBox, modified.boundingBox)) {
      return {
        correctedLineGuid: original.guid,
        boundingBox: modified.boundingBox,
        words: null,
        type: CorrectionType.UPDATE,
      };
    } else {
      return {
        correctedLineGuid: original.guid,
        boundingBox: original.boundingBox,
        words: null,
        type: CorrectionType.NONE,
      };
    }
  }

  private evaluateDiffInWord(
    original: StructuredWord,
    modified: StructuredWord
  ): StructuredWordCorrection {
    if (
      original.content !== modified.content ||
      !this.sameRectangles(original.boundingBox, modified.boundingBox)
    ) {
      return {
        correctedWordGuid: original.guid,
        boundingBox: modified.boundingBox,
        content: modified.content,
        type: CorrectionType.UPDATE,
      };
    } else {
      return {
        correctedWordGuid: original.guid,
        boundingBox: null,
        content: null,
        type: CorrectionType.NONE,
      };
    }
  }

  // *************************************************
  // *** MERGE STRUCTURED CONTENT WITH CORRECTIONS ***
  // *************************************************

  // mergeStructuredContent(
  //   original: StructuredContent,
  //   correction: StructuredContentCorrection
  // ) {
  //   for (let correctedLine of correction.lines) {
  //     const originalLine = original.lines.find(
  //       (item) => item.guid === correctedLine.correctedLineGuid
  //     );
  //     switch (correctedLine.type) {
  //       case CorrectionType.INSERT_AFTER:
  //         this.addLine(original, correctedLine);
  //         this.mergeStructuredWords(originalLine, correctedLine);
  //         break;
  //       case CorrectionType.DELETE:
  //         this.deleteLine(original, correctedLine);
  //         break;
  //       case CorrectionType.UPDATE:
  //         this.updateLine(original, correctedLine);
  //         this.mergeStructuredWords(originalLine, correctedLine);
  //         break;
  //       case CorrectionType.NONE:
  //         // just update words
  //         this.mergeStructuredWords(originalLine, correctedLine);
  //         break;
  //       default:
  //         this.logService.warn(
  //           TAG,
  //           "Unknown correction type for line " +
  //             correctedLine.correctedLineGuid
  //         );
  //     }
  //   }
  // }

  // private mergeStructuredWords(
  //   originalLine: StructuredLine,
  //   correctedLine: StructuredLineCorrection
  // ) {
  //   for (let correctedWord of correctedLine.words) {
  //     switch (correctedWord.type) {
  //       case CorrectionType.INSERT_AFTER:
  //         this.addWord(originalLine.words, correctedWord);
  //         break;
  //       case CorrectionType.DELETE:
  //         this.deleteWord(originalLine.words, correctedWord);
  //         break;
  //       case CorrectionType.UPDATE:
  //         this.updateWord(originalLine.words, correctedWord);
  //         break;
  //       case CorrectionType.NONE:
  //         break;
  //       default:
  //         this.logService.warn(
  //           TAG,
  //           "Unknown correction type for word " +
  //             correctedWord.correctedWordGuid
  //         );
  //     }
  //   }
  // }

  // private addLine(
  //   structuredContent: StructuredContent,
  //   newLine: StructuredLineCorrection
  // ) {
  //   const insertAfterLine = structuredContent.lines.findIndex(
  //     (item) => item.guid === newLine.correctedLineGuid
  //   );
  //   structuredContent.lines.splice(insertAfterLine + 1, 0, {
  //     guid: SharedService.generateGuid(),
  //     boundingBox: newLine.boundingBox,
  //     words: [],
  //   });
  // }

  // private updateLine(
  //   structuredContent: StructuredContent,
  //   changedLine: StructuredLineCorrection
  // ) {
  //   const originalLine = structuredContent.lines.find(
  //     (item) => item.guid === changedLine.correctedLineGuid
  //   );
  //   if (originalLine) {
  //     originalLine.boundingBox = changedLine.boundingBox;
  //   }
  // }

  // private deleteLine(
  //   structuredContent: StructuredContent,
  //   deletedLine: StructuredLineCorrection
  // ) {
  //   const deleteLine = structuredContent.lines.findIndex(
  //     (item) => item.guid === deletedLine.correctedLineGuid
  //   );
  //   structuredContent.lines.splice(deleteLine, 1);
  // }

  // private addWord(
  //   structuredWords: StructuredWord[],
  //   newWord: StructuredWordCorrection
  // ) {
  //   const insertAfterWord = structuredWords.findIndex(
  //     (item) => item.guid === newWord.correctedWordGuid
  //   );
  //   structuredWords.splice(insertAfterWord + 1, 0, {
  //     guid: SharedService.generateGuid(),
  //     boundingBox: newWord.boundingBox,
  //     content: newWord.content,
  //     charConfidence: Array(newWord.content.length).fill(0),
  //     wordConfidence: 1,
  //   });
  // }

  // private updateWord(
  //   structuredWords: StructuredWord[],
  //   changedWord: StructuredWordCorrection
  // ) {
  //   const originalWord = structuredWords.find(
  //     (item) => item.guid === changedWord.correctedWordGuid
  //   );
  //   if (originalWord) {
  //     originalWord.content = changedWord.content;
  //     originalWord.boundingBox = changedWord.boundingBox;
  //     originalWord.charConfidence = Array(changedWord.content.length).fill(0);
  //     originalWord.wordConfidence = 1;
  //   }
  // }

  // private deleteWord(
  //   structuredWords: StructuredWord[],
  //   deletedWord: StructuredWordCorrection
  // ) {
  //   const deleteWord = structuredWords.findIndex(
  //     (item) => item.guid === deletedWord.correctedWordGuid
  //   );
  //   structuredWords.splice(deleteWord, 1);
  // }

  private sameRectangles(a: RectangleByPoints, b: RectangleByPoints): boolean {
    if (
      a.a.x !== b.a.x ||
      a.a.y !== b.a.y ||
      a.b.x !== b.b.x ||
      a.b.y !== b.b.y ||
      a.c.x !== b.c.x ||
      a.c.y !== b.c.y ||
      a.d.x !== b.d.x ||
      a.d.y !== b.d.y
    ) {
      return false;
    } else {
      return true;
    }
  }
}

export enum PageTextType {
  PLAIN_TEXT = 1,
  STRUCTURED_TEXT = 2,
  ALL = 3,
}
