import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {
  ContentType,
  IccContent,
  IccDocument,
  IccFolder,
  IccPage,
  ROOT_NODE_NAME,
} from "src/app/models/icc-content.model";
import { IcwsStorageService } from "./icws-storage.service";
import { LoggingService } from "./log.service";
import { MockDataService, MOCK_ROOT_NODE_ID } from "./mock-data.service";
import { ImageVariantType } from "../proto/generated/icws_proto/icws_api_gateway/storage/storage_types_pb";
import {
  Folder,
  Document,
  Scan,
} from "../proto/generated/icws_proto/icws_api_gateway/storage/base_pb";
import { SharedService } from "./shared.service";
import { ContentSource } from "../models/common";
import { GlobalService } from "./global.service";

/** @ignore */
const TAG = "ContentLoadService";

@Injectable({
  providedIn: "root",
})
export class ContentLoadService {
  private referenceContentFlat: IccContent[] = [];
  private referenceContentTree: IccContent[] = [];
  private contentTreeSubject = new BehaviorSubject<IccContent[]>(this.referenceContentTree);
  readonly contentTreeObservable = this.contentTreeSubject.asObservable();
  private icwsRootId: string = null;

  constructor(
    private storageService: IcwsStorageService,
    private mockDataService: MockDataService,
    private logService: LoggingService,
    private globalService: GlobalService
  ) {}

  public getRootId(): string {
    switch (this.globalService.currentContentSource()) {
      // for DEMO/TOUR a symbolic rootId MOCK_ROOT_NODE_ID is used
      case ContentSource.APP_TOUR:
      case ContentSource.DEMO:
        return MOCK_ROOT_NODE_ID;
      case ContentSource.ICWS:
        return this.icwsRootId;
      default:
        return null;
    }
  }

  public initIcwsRootId(): Promise<string> {
    return this.storageService
      .getRootFolder()
      .then((rootFolderResponse) => {
        const rootFolderId = rootFolderResponse.getFolder().getId();
        if (rootFolderId) {
          this.icwsRootId = rootFolderId;
          this.logService.debug(TAG, "Root Folder Id: " + this.icwsRootId);
          return this.icwsRootId;
        } else {
          this.logService.error(TAG, "Root folder Id is null.");
          return null;
        }
      })
      .catch((err) => {
        this.logService.error(TAG, "Error when loading root folder Id.", err);
        return null;
      });
  }

  /**
   * Get a deep copy of current content tree.
   * @returns Deep copy of current content tree.
   */
  getContentTree(deepCopy: boolean): IccContent[] {
    if (!deepCopy) {
      return this.referenceContentTree;
    } else {
      return SharedService.deepCopy(this.referenceContentTree);
    }
  }

  clearContent() {
    this.referenceContentFlat = [];
    this.referenceContentTree = [];
    this.contentTreeSubject.next(this.referenceContentTree);
    this.icwsRootId = null;
  }

  loadContent(
    folderId: string = this.getRootId(),
    includePages: boolean = false,
    contentSource: ContentSource,
    silent: boolean = false
  ): Promise<void> {
    return new Promise((resolve) => {
      switch (contentSource) {
        case ContentSource.APP_TOUR:
        case ContentSource.DEMO:
          this.logService.debug(TAG, "Request to load content tree from demo data source.");
          this.referenceContentFlat = this.mockDataService.getMockContentFlat(contentSource);
          this.publishContent(silent);
          resolve();
          break;

        case ContentSource.ICWS:
          this.logService.debug(
            TAG,
            'Request to load the content tree of node "' + folderId + '" from ICWS.'
          );
          this.loadFolderFromIcws(folderId, includePages, true).then((content) => {
            this.referenceContentFlat = content;
            this.publishContent(silent);
            resolve();
          });
          break;
      }
    });
  }

  getNode(
    nodeId: string,
    loadMode: LoadMode,
    nodeType?: ContentType,
    silent: boolean = false
  ): Promise<IccContent> {
    let node: IccContent;
    let id: string;
    let type: ContentType;
    return new Promise((resolve, reject) => {
      node = SharedService.findNodeInTree(this.referenceContentTree, nodeId); // try to find the requested node in content which was loaded already
      if (loadMode === LoadMode.LOAD_WHEN_NEEDED && node && node.valid) {
        // Node is loaded already and refresh is not required -> there is nothing to do, just return the node.
        resolve(node);
      } else {
        if (node) {
          id = node.icwsId;
          type = node.type;
        } else {
          id = nodeId;
          type = nodeType;
        }
        if (id && type !== undefined) {
          // Load requested node from ICWS
          this.logService.debug(
            TAG,
            'Request to load node "' +
              id +
              '" (' +
              ContentType[type] +
              ") from ICWS" +
              (loadMode === LoadMode.FORCE_LOAD_SUBTREE ? " with subtree." : ".")
          );
          this.loadNodeFromIcws(id, type, loadMode === LoadMode.FORCE_LOAD_SUBTREE)
            .then((loadedContent) => {
              // Add loaded node to content
              this.updateNodes(loadedContent, silent);
              resolve(this.findNode(id));
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          this.logService.debug(
            TAG,
            "Request to retrieve content from ICWS without specifying its type or Id.",
            nodeId,
            nodeType
          );
          resolve(null);
        }
      }
    });
  }

  /**
   * Updates nodes in the list and publish an updated content tree.
   * @param updatedNodes Nodes to be updated/added to the list.
   */
  updateNodes(updatedNodes: IccContent[], silent: boolean = false) {
    for (let updatedNode of updatedNodes) {
      let node = this.findNode(updatedNode.icwsId);
      let nodeIndex = this.referenceContentFlat.findIndex(
        (item) => item.icwsId === updatedNode.icwsId
      );
      if (nodeIndex >= 0) {
        // replace existing node
        //
        // TODO - fix of bug: getFolder return from getParentId() always null
        if (updatedNode.type === ContentType.FOLDER && updatedNode.parentId === null) {
          updatedNode.parentId = node.parentId;
        }
        // end of fix
        //
        this.referenceContentFlat.splice(nodeIndex, 1, updatedNode);
      } else {
        // add new node
        this.referenceContentFlat.push(updatedNode);
      }
    }
    this.publishContent(silent);
  }

  removeNodes(removedNodes: string[], silent: boolean = false) {
    for (let removedNodeId of removedNodes) {
      let nodeIndex = this.referenceContentFlat.findIndex((item) => item.icwsId === removedNodeId);
      this.referenceContentFlat.splice(nodeIndex, 1);
    }
    this.publishContent(silent);
  }

  invalidateNodes(nodesIdToInvalidate: string[], silent: boolean = false) {
    for (let nodeIdToInvalidate of nodesIdToInvalidate) {
      const nodeInFlat = this.referenceContentFlat.find(
        (item) => item.icwsId === nodeIdToInvalidate
      );
      const nodeInTree = SharedService.findNodeInTree(
        this.getContentTree(false),
        nodeIdToInvalidate
      );
      nodeInFlat.valid = false;
      nodeInTree.valid = false;
    }
    this.publishContent(silent);
  }

  private loadNodeFromIcws(
    nodeId: string,
    nodeType: ContentType,
    recursive: boolean = false
  ): Promise<IccContent[]> {
    switch (nodeType) {
      case ContentType.FOLDER:
        return this.loadFolderFromIcws(nodeId, false, recursive);
      case ContentType.DOCUMENT:
        return this.loadDocumentFromIcws(nodeId);
      case ContentType.PAGE:
        return this.loadPageFromIcws(nodeId);
      default:
        throw 'Unable to load node "' + nodeId + '" without specifying its type.';
    }
  }

  private loadFolderFromIcws(
    folderId: string,
    includePages: boolean,
    recursive: boolean
  ): Promise<IccContent[]> {
    let newNodes: IccContent[] = [];

    const loadSingleFolder = (folderId: string, recursively: boolean) => {
      return new Promise((resolve, reject) => {
        this.storageService
          .listFolder(folderId, null, includePages, false)
          .then(async (folder) => {
            for (let document of folder.getDocumentsList()) {
              if (includePages) {
                for (let page of document.getScansList()) {
                  // const pageResponse = await this.storageService.getPage(
                  //   page.getId(),
                  //   ImageVariantType.NONE
                  // );
                  this.addIcwsPage(
                    newNodes,
                    page,
                    document.getId(),
                    true // TODO - Should page be invalid by default? It will be necessary to add OCR data to it later.
                    // pageResponse.getProcessedScansIdsList()
                  );
                }
              }
              await this.storageService.getDocument(
                document.getId(),
                ImageVariantType.NONE,
                false,
                false
              );
              this.addIcwsDocument(
                newNodes,
                document,
                folderId,
                includePages ? true : false // If scans are requested by ICWS, the content is valid. Otherwise, no.
              );
            }
            if (folder.getFoldersList().length > 0) {
              resolve(
                Promise.all(
                  folder.getFoldersList().map((subfolder) => {
                    if (recursively) {
                      // If recursive mode is requested, continue by loading the content of subfolders.
                      return loadSingleFolder(subfolder.getId(), recursively)
                        .then((_) => {
                          this.addIcwsFolder(newNodes, subfolder, folderId, true);
                          return;
                        })
                        .catch((error) => {
                          // Error while loading folder content - may be due to denied access (error code = 7)
                        });
                    } else {
                      // If recursive mode is not required, save only the list of subfolders under the current folder.
                      this.addIcwsFolder(
                        newNodes,
                        subfolder,
                        folderId,
                        false // The documents in the folder are not updated -> the folder must be invalid.
                      );
                      return;
                    }
                  })
                )
              );
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    return new Promise(async (resolve) => {
      // Get the folder itself
      if (folderId === this.getRootId()) {
        newNodes.push(
          new IccFolder({
            icwsId: folderId,
            name: ROOT_NODE_NAME,
            parentId: null,
            valid: true,
          })
        );
      } else {
        let folder: Folder = (await this.storageService.getFolder(folderId)).getFolder();
        this.addIcwsFolder(newNodes, folder, folder.getParentId(), true);
      }

      loadSingleFolder(folderId, recursive).then((_) => {
        resolve(newNodes);
      });
    });
  }

  private loadDocumentFromIcws(documentId: string): Promise<IccContent[]> {
    let newNodes: IccContent[] = [];
    return new Promise(async (resolve, reject) => {
      this.storageService
        .getDocument(documentId, ImageVariantType.NONE, true, false)
        .then(async (docResponse) => {
          this.addIcwsDocument(
            newNodes,
            docResponse.getDocument(),
            docResponse.getDocument().getParentId(),
            true
          );
          for (let page of docResponse.getDocument().getScansList()) {
            // const pageResponse = await this.storageService.getPage(
            //   page.getId(),
            //   ImageVariantType.NONE
            // );
            this.addIcwsPage(
              newNodes,
              page,
              docResponse.getDocument().getId(),
              true // TODO - Should page be invalid by default? It will be necessary to add OCR data to it later.
              // pageResponse.getProcessedScansIdsList()
            );
          }
          resolve(newNodes);
        })
        .catch((error) => reject(error));
    });
  }

  private loadPageFromIcws(pageId: string): Promise<IccContent[]> {
    let newNodes: IccContent[] = [];
    return new Promise(async (resolve, reject) => {
      let pageLoaded: IccContent = this.findNode(pageId);
      if (pageLoaded && pageLoaded.type == ContentType.PAGE) {
        // There is no new node to add to the content.
        pageLoaded.valid = true;
        //TODO Add OCR data dowload when it will be implemented on the server. In the meantime, return page record as always valid
        resolve([]);
      } else {
        this.storageService
          .getPage(pageId, null)
          .then((page) => {
            this.addIcwsPage(
              newNodes,
              page.getScan(),
              page.getScan().getDocumentId(),
              true
              // page.getProcessedScansIdsList()
            );
            //TODO Add OCR data dowload when it will be implemented on the server. In the meantime, return page record as always valid
            resolve(newNodes);
          })
          .catch((error) => reject(error));
      }
    });
  }

  /** Returns the ID of the first page of the document (used when thumbnailId is not set on the document) */
  getFirstPageOfDocument(documentId: string): Promise<string> {
    return new Promise(async (resolve) => {
      this.storageService
        .getDocument(documentId, ImageVariantType.NONE, true, false)
        .then((document) => {
          let pageList = document.getDocument().getScansList();
          if (pageList.length === 0) {
            resolve(null);
          } else {
            pageList.sort((a, b) => {
              // Pages are sorted regarding to their order attribute if possible, otherwise alphabetically
              const orderA: number = parseInt(a.getMetadata()?.toJavaScript().order?.toString());
              const orderB: number = parseInt(b.getMetadata()?.toJavaScript().order?.toString());
              const nameA: string = a.getMetadata()?.toJavaScript().name?.toString();
              const nameB: string = b.getMetadata()?.toJavaScript().name?.toString();
              if (orderA && orderB) return orderA - orderB;
              else return nameA.localeCompare(nameB);
            });
            resolve(pageList[0].getId());
          }
        });
    });
  }

  private findNode(nodeId: string): IccContent {
    for (let node of this.referenceContentFlat) {
      if (node.icwsId === nodeId) {
        return node;
      }
    }
    return null;
  }

  private publishContent(silent: boolean = false) {
    this.sortFlatContent(this.referenceContentFlat);
    this.referenceContentTree = this.getTreeFromFlatContent(this.referenceContentFlat);
    if (silent) {
      this.logService.debug(
        TAG,
        "Shared content updated silently.",
        SharedService.deepCopy(this.referenceContentTree)
      );
    } else {
      this.contentTreeSubject.next(this.referenceContentTree);
      this.logService.debug(
        TAG,
        "Shared content updated.",
        SharedService.deepCopy(this.referenceContentTree)
      );
    }
  }

  private sortFlatContent(content: IccContent[]) {
    content.sort((a, b) => {
      if (a.parentId != b.parentId) {
        // Primarily group items by parentId
        return a.parentId?.localeCompare(b.parentId);
      } else {
        // Items with the same parentId sort by multiple critera
        if (a.type !== b.type) {
          // If items aren't the same type, sort them regardint to their type FOLDER=0 < DOCUMENT=1 < PAGE=2;
          return a.type - b.type;
        } else {
          if (a.type === ContentType.PAGE) {
            // Pages are sorted regarding to their order attribute if possible, otherwise alphabetically
            const aPage: IccPage = <IccPage>a;
            const bPage: IccPage = <IccPage>b;
            if (aPage.order && bPage.order) return aPage.order - bPage.order;
            else return aPage.name.localeCompare(bPage.name);
          } else {
            // Other item types are sorted alphabetically
            return a.name.localeCompare(b.name);
          }
        }
      }
    });
  }

  private getTreeFromFlatContent(flatContent: IccContent[]): IccContent[] {
    const hierarchy = (data: IccContent[]) => {
      const tree: IccContent[] = [];
      const childOf = {};
      data.forEach((item) => {
        const { icwsId, parentId } = item;
        childOf[icwsId] = childOf[icwsId] || [];
        if (item.type != ContentType.PAGE)
          (<IccFolder | IccDocument>item).children = childOf[icwsId];
        parentId ? (childOf[parentId] = childOf[parentId] || []).push(item) : tree.push(item);
      });
      return tree;
    };
    let contentTree: IccContent[] = [...this.referenceContentFlat];
    return hierarchy(contentTree);
  }

  private addIcwsFolder(
    list: IccContent[],
    folder: Folder,
    parentId: string,
    isValid: boolean = true
  ) {
    list.push(
      new IccFolder({
        icwsId: folder.getId(),
        name: folder.getMetadata().toJavaScript().name?.toString(),
        parentId: parentId,
        valid: isValid,
      })
    );
  }

  private addIcwsDocument(
    list: IccContent[],
    document: Document,
    parentId: string,
    isValid: boolean = true
  ) {
    list.push(
      new IccDocument({
        icwsId: document.getId(),
        name: document.getMetadata().toJavaScript().name?.toString(),
        parentId: parentId,
        valid: isValid,
        my_allowed_activites: document.getMyAllowedActivitiesList(),
        permissions: document.getPermissionsList(),
        thumbnailPageId: document.getMetadata().toJavaScript().thumbnailPage?.toString(),
        scans_num: document.getScansNum(),
        documentState: SharedService.getIccDocumentState(document.getState()),
      })
    );
  }

  private addIcwsPage(
    list: IccContent[],
    page: Scan,
    parentId: string,
    isValid: boolean = true
    // processedPageIds: string[]
  ) {
    const pageName: string = page.getMetadata()?.toJavaScript().name?.toString();
    const pageOrder: number = parseInt(page.getMetadata()?.toJavaScript().order?.toString());
    const pageQuality: number = parseFloat(page.getMetadata()?.toJavaScript().quality?.toString());
    list.push(
      new IccPage({
        icwsId: page.getId(),
        name: pageName ? pageName : "page",
        order: pageOrder ? pageOrder : null,
        quality: pageQuality ? pageQuality : 1,
        parentId: parentId,
        valid: isValid,
        pageState: SharedService.getIccPageState(page.getState()),
        // processedPageIds: processedPageIds,
      })
    );
  }
}

export enum LoadMode {
  LOAD_WHEN_NEEDED = 0,
  FORCE_LOAD = 1,
  FORCE_LOAD_SUBTREE = 2,
}
