import { Injectable } from "@angular/core";
import { LocalStorageService, STORAGE_KEY_TOKEN } from "./local-storage.service";
import { BehaviorSubject } from "rxjs";
import { User } from "../models/user.model";

import { LoggingService } from "./log.service";
import jwt_decode from "jwt-decode";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { IamService } from "./iam.service";
import { AppMode } from "src/app/models/common";
import { GlobalService } from "./global.service";
import { IcwsStorageService } from "./icws-storage.service";
import { ContentLoadService } from "./content-load.service";
import { IcwsAuthService } from "./icws-auth.service";
import { IcwsProcessingService } from "./icws-processing.service";
import { IcwsLoggingService } from "./icws-logging.service";
import { IcwsSearchingService } from "./icws-searching.service";

/** @ignore */
const TAG = "AuthenticationService";

/**
 * Properties of a decoded JWT auth token.
 */
export type DecodedToken = {
  exp: number;
  iat: number;
  licence_id: string;
  name: string;
  release_id: string;
  sub: string;
  user_id: string;
};

/**
 * Provides functions related to user authentication.
 */
@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private currentUser: User;

  constructor(
    private iamService: IamService,
    private icwsStorageService: IcwsStorageService,
    private icwsAuthService: IcwsAuthService,
    private icwsProcessingService: IcwsProcessingService,
    private contentLoadService: ContentLoadService,
    private localStorage: LocalStorageService,
    private logService: LoggingService,
    private translate: TranslateService,
    private globalService: GlobalService,
    private icwsLoggingService: IcwsLoggingService,
    private icwsSearchingService: IcwsSearchingService
  ) {}

  getCurrentUser(): User {
    return this.currentUser;
  }

  /** Evaluates the permissions of the currently logged user
   * to modify data in the application.
   */
  isUserAuthorized(): boolean {
    if (this.globalService.currentAppMode() !== AppMode.NORMAL || environment.mock_data) {
      // When app is not in production mode or mock data is used, user is not allowed to manupulate with the data.
      return false;
    } else {
      // TODO - Evaluate authorization for production mode
      if (this.isAuthenticated.getValue()) {
        return true;
      }
    }
  }

  /** Loads stored token and provides auto-login.
   * Token can be pushed by the environment parameter "autologin_token".
   */
  async loadToken(): Promise<void> {
    let token: string;

    if (this.globalService.currentAppMode() === AppMode.NORMAL) {
      // override_login_token allows to act as another user
      if (environment.override_login_token.length > 0) {
        this.logService.info(TAG, "Token overriden by environment settings.");
        token = environment.override_login_token;
        await this.localStorage.set(STORAGE_KEY_TOKEN, token);
      } else {
        token = await this.localStorage.get(STORAGE_KEY_TOKEN);
      }

      if (token) {
        // Check token expiration
        const decodedToken: DecodedToken = jwt_decode(token);
        if (this.isTokenExpired(decodedToken)) {
          this.logService.info(TAG, "User not authenticated, token is expired.");
          this.clearIcwsUserData();
          this.isAuthenticated.next(false);
          return;
        }
        // Set user info from IAM on login.
        try {
          await this.initIcwsUserData(token);
          this.isAuthenticated.next(true);
          this.logService.info(TAG, "User authenticated as " + this.currentUser.email);
        } catch (err) {
          this.clearIcwsUserData();
          this.isAuthenticated.next(false);
          this.logService.error(TAG, "User not authenticated.", err);
        }
      } else {
        // There is no token.
        this.isAuthenticated.next(false);
        this.clearIcwsUserData();
      }
    } else if (this.globalService.currentAppMode() === AppMode.DEMO) {
      this.logService.info(TAG, "Demo user account is used.");
      this.currentUser = {
        email: "",
        name: "",
        surname: "Demo user",
      };
      this.isAuthenticated.next(true);
    } else if (this.globalService.currentAppMode() === AppMode.ANONYMOUS_USER) {
      this.logService.info(TAG, "Anonymous user account is used.");
      this.currentUser = {
        email: "",
        name: "",
        surname: "Anonymous user",
      };
      this.isAuthenticated.next(true);
    }
  }

  /** Function called from login page */
  nativeLogin(credentials: { email: string; password: string }): Promise<User> {
    return new Promise(async (resolve, reject) => {
      try {
        let loginResponse = await this.iamService.login(credentials.email, credentials.password);

        if (loginResponse.status === "OK") {
          await this.localStorage.set(STORAGE_KEY_TOKEN, loginResponse.token);
          try {
            // Set user info from IAM on login.
            await this.initIcwsUserData(loginResponse.token);
            this.isAuthenticated.next(true);
            this.logService.info(TAG, "User logged-in as " + this.currentUser.email);
            resolve(this.currentUser);
          } catch (err) {
            this.clearIcwsUserData();
            this.isAuthenticated.next(false);
            this.logService.error(TAG, "User not authenticated.", err);
            reject(err);
          }
        } else {
          this.isAuthenticated.next(false);
          reject(this.translate.instant("authenticationService.nativeAuthFailed"));
        }
      } catch (err) {
        this.isAuthenticated.next(false);
        this.clearIcwsUserData();
        reject(this.translate.instant("authenticationService.nativeAuthError"));
      }
    });
  }

  private async initIcwsUserData(token: string): Promise<void> {
    // Set user info from IAM.
    let profileInfo = await this.iamService.getProfileInfo();
    this.currentUser = {
      email: profileInfo.user.username,
      name: profileInfo.user.name,
      surname: profileInfo.user.surname,
    };
    // Initialize ICWS communication.
    await this.icwsStorageService.initMetadata(token);
    await this.icwsAuthService.initMetadata(token);
    await this.icwsProcessingService.initMetadata(token);
    await this.icwsLoggingService.initMetadata(token);
    await this.icwsSearchingService.initMetadata(token);
    await this.contentLoadService.initIcwsRootId();
    this.icwsStorageService.getUserStorageInfo();
  }

  private clearIcwsUserData() {
    // Clear current user
    this.currentUser = null;
    // Clear local ICWS data.
    this.icwsStorageService.clearMetadata();
    this.icwsAuthService.clearMetadata();
    this.icwsProcessingService.clearMetadata();
    this.icwsLoggingService.clearMetadata();
    this.icwsSearchingService.clearMetadata();
    this.contentLoadService.clearContent();
  }

  /**
   * Logs out a user by clearing all in memory and storage
   * related variables.
   * @returns
   */
  async logout(): Promise<void> {
    this.isAuthenticated.next(false);
    await this.clearIcwsUserData();
    this.logService.info(TAG, "User is logged out.");
    await this.localStorage.remove(STORAGE_KEY_TOKEN).then((_) => {
      window.location.reload();
    });
  }

  /**
   * Checks if a token is expired.
   * @param decodedToken Structure decoded from a JWT string token.
   * @returns
   */
  isTokenExpired(decodedToken: DecodedToken) {
    return Date.now() > decodedToken.exp * 1000;
  }
}
