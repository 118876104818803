import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage-angular";
import { AppMode, GlobalConstants } from "../models/common";
import { LoggingService } from "./log.service";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { CookieService } from "ngx-cookie-service";
import { __await } from "tslib";
import { GlobalService } from "./global.service";
import { IamService } from "./iam.service";

/** @ignore */
const TAG = "LocalStorageService";

// LOCAL STORAGE KEYS
/** The key used to store user authentication token. Used for auto login. */
export const STORAGE_KEY_TOKEN = "iccToken";
/** The key used to store user gdpr consents. */
export const STORAGE_KEY_CONSENT_LOCAL_STORAGE = "iccUserConsents";
/** Key used to map page caching fuctionality to GDPR consent type. */
export const STORAGE_KEY_PAGE_CACHE = "iccPageCache";
/** The key used to store selected application language. */
export const STORAGE_KEY_LANGUAGE = "iccLanguage";
/** The key used to store whether application introduction tour has been seen. */
export const STORAGE_KEY_APP_TOUR_SEEN = "iccAppTourSeen";
/** Key name in local storage to store Google Analytics Client ID */
export const STORAGE_KEY_GA_CLIENT_ID = "iccGaClientId";
/** The key used to store whether GDPR consent type was knowingly selected. */
export const STORAGE_KEY_GDPR_SELECTED = "iccGdprSelected";

/** Group key used to store app state. */
export const STORAGE_GROUP_KEY_APP_STATE = "iccAppState";
/** The key used to store status of a specific side panel. */
export const STORAGE_GROUP_SUBKEY_PANEL_STATE_BROWSE_FOLDERS_LEFT = "iccPanelOpenBrowseFoldersLeft";
/** The key used to store status of a specific side panel. */
export const STORAGE_GROUP_SUBKEY_PANEL_STATE_BROWSE_DOCUMENT_LEFT =
  "iccPanelOpenBrowseDocumentLeft";
/** The key used to store status of a specific side panel. */
export const STORAGE_GROUP_SUBKEY_PANEL_STATE_BROWSE_DOCUMENT_RIGHT =
  "iccPanelOpenBrowseDocumentRight";
/** The key used to store status of a specific side panel. */
export const STORAGE_GROUP_SUBKEY_PANEL_STATE_SEARCH_RESULTS_LEFT = "iccPanelOpenSearchResultsLeft";
/** The key used to store status of a specific side panel. */
export const STORAGE_GROUP_SUBKEY_PANEL_STATE_SEARCH_RESULTS_RIGHT =
  "iccPanelOpenSearchResultsRight";
/** The key used to store status of a specific side panel. */
export const STORAGE_GROUP_SUBKEY_PANEL_STATE_EDIT_PAGE_OCR_RIGHT = "iccPanelOpenEditPageOcrRight";
/** The key used to store user notes. */
export const STORAGE_GROUP_SUBKEY_USER_NOTES = "iccUserNotes";
/** The key used to store OCR edit option (focus to selected word). */
export const STORAGE_GROUP_SUBKEY_EDIT_PAGE_WORD_FOCUS = "iccPageEditWordFocus";
/** The key used to store OCR edit option (word certainty threshold). */
export const STORAGE_GROUP_SUBKEY_EDIT_PAGE_WER_THRESHOLD = "iccPageEditWerThreshold";
/** The key used to store OCR edit option (char certainty threshold). */
export const STORAGE_GROUP_SUBKEY_EDIT_PAGE_CER_THRESHOLD = "iccPageEditCerThreshold";

// GDPR STUFF
/** Stucture to record status of one GDPR consent */
export interface GdprConsent {
  type: GdprConsentType; // unique identification of consent category
  name: string; // text name of the category (for easier identification in local storage)
  accepted: boolean; // status of the consent
  lastChanged: Date; // last change datetime
}
/** GDPR consent category */
export enum GdprConsentType {
  NECESSARY = 0,
  PREFERENTIAL = 1,
  ANALYTICAL = 2,
  // MARKETING = 3,
}
/** Maps keys stored locally to GDPR consent categories */
const keyInConsent: Map<string, GdprConsentType> = new Map([
  [STORAGE_KEY_TOKEN, GdprConsentType.NECESSARY],
  [STORAGE_KEY_CONSENT_LOCAL_STORAGE, GdprConsentType.NECESSARY],
  [STORAGE_KEY_PAGE_CACHE, GdprConsentType.NECESSARY],
  [STORAGE_KEY_APP_TOUR_SEEN, GdprConsentType.NECESSARY],
  [STORAGE_KEY_GDPR_SELECTED, GdprConsentType.NECESSARY],
  [STORAGE_KEY_LANGUAGE, GdprConsentType.NECESSARY],
  [STORAGE_GROUP_KEY_APP_STATE, GdprConsentType.PREFERENTIAL],
  [STORAGE_KEY_GA_CLIENT_ID, GdprConsentType.ANALYTICAL],
]);

export const defaultGdprConsents: GdprConsent[] = [
  {
    type: GdprConsentType.NECESSARY,
    name: GdprConsentType[GdprConsentType.NECESSARY],
    accepted: true,
    lastChanged: new Date(),
  },
  {
    type: GdprConsentType.PREFERENTIAL,
    name: GdprConsentType[GdprConsentType.PREFERENTIAL],
    accepted: false,
    lastChanged: new Date(),
  },
  {
    type: GdprConsentType.ANALYTICAL,
    name: GdprConsentType[GdprConsentType.ANALYTICAL],
    accepted: false,
    lastChanged: new Date(),
  },
  // {
  //   type: GdprConsentType.MARKETING,
  //   name: GdprConsentType[GdprConsentType.MARKETING],
  //   accepted: false,
  //   lastChanged: new Date(),
  // },
];

export const fullGdprConsents: GdprConsent[] = [
  {
    type: GdprConsentType.NECESSARY,
    name: GdprConsentType[GdprConsentType.NECESSARY],
    accepted: true,
    lastChanged: new Date(),
  },
  {
    type: GdprConsentType.PREFERENTIAL,
    name: GdprConsentType[GdprConsentType.PREFERENTIAL],
    accepted: true,
    lastChanged: new Date(),
  },
  {
    type: GdprConsentType.ANALYTICAL,
    name: GdprConsentType[GdprConsentType.ANALYTICAL],
    accepted: true,
    lastChanged: new Date(),
  },
  // {
  //   type: GdprConsentType.MARKETING,
  //   name: GdprConsentType[GdprConsentType.MARKETING],
  //   accepted: true,
  //   lastChanged: new Date(),
  // },
];

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  private _storage: Storage | null = null;
  private _memoryStorage: Map<string, any> = new Map<string, any>();
  private _gdprConsents: GdprConsent[];

  /** @ignore */
  constructor(
    private iamService: IamService,
    private storage: Storage,
    private logService: LoggingService,
    private cookieService: CookieService,
    private globalService: GlobalService
  ) {}

  async initStorage() {
    if (this._storage != null) {
      return;
    }
    if (this._storage == null) {
      const storage = await this.storage.create();
      this._storage = storage;
    }
  }

  async initGdprConsents() {
    if (this._gdprConsents != null) {
      return;
    }
    if (this._gdprConsents == null) {
      const consents = await this.loadGdprConsents();
      if (consents) {
        this._gdprConsents = consents;
      } else {
        this._gdprConsents = defaultGdprConsents;
        this.logService.info(TAG, "GDPR consents sets to default:'", this._gdprConsents);
      }
    }
  }

  public async set(
    key: string,
    value: any,
    preventLocalStorageWrite: boolean = false
  ): Promise<any> {
    this._memoryStorage.set(key, value);
    if (this.isLocalStorageAllowed(key) && !preventLocalStorageWrite) {
      await this._storage?.set(key, value);
      this.logService.debug(
        TAG,
        "Local Storage update - key '" + key + "' set to '" + value + "'."
      );
    }
    return;
  }

  public async get(key: string): Promise<any> {
    if (this._memoryStorage.has(key)) {
      return this._memoryStorage.get(key);
    } else {
      // If value has not been read yet, try to load it from local storage
      // (value in local storage should be only if "cookie consent" was given previously);
      const storedValue = await this._storage?.get(key);
      this._memoryStorage.set(key, storedValue);
      return storedValue;
    }
  }

  public async remove(key: string, forceRemoveFromLocalStorage: boolean = false): Promise<any> {
    this._memoryStorage.delete(key);
    if (forceRemoveFromLocalStorage || this.isLocalStorageAllowed(key)) {
      await this._storage?.remove(key);
      this.logService.debug(TAG, "Local Storage update - key '" + key + "' removed.");
    }
    return;
  }

  public async setGrouped(group: string, key: string, value: any): Promise<any> {
    let groupedValue: Map<string, any> = await this.get(group);
    if (!groupedValue) {
      groupedValue = new Map<string, any>();
    }
    groupedValue.set(key, value);
    this._memoryStorage.set(group, groupedValue);
    if (this.isLocalStorageAllowed(group)) {
      await this._storage?.set(group, groupedValue);
      this.logService.debug(
        TAG,
        "Local Storage update - group key '" + group + "' set. ",
        groupedValue
      );
    }
    return;
  }

  public async getGrouped(group: string, key: string): Promise<any> {
    const groupedValue = await this.get(group);
    return groupedValue?.get(key);
  }

  isLocalStorageAllowed(key: string): boolean {
    if (this._gdprConsents) {
      const consentTypeRequested = keyInConsent.get(key);
      const requiredConsent = this._gdprConsents.find(
        (consent) => consent.type == consentTypeRequested
      );
      return requiredConsent?.accepted;
    } else {
      return false;
    }
  }

  async loadGdprConsents(): Promise<GdprConsent[]> {
    let consents: GdprConsent[];

    if (
      this.globalService.currentAppMode() == AppMode.DEMO ||
      this.globalService.currentAppMode() == AppMode.ANONYMOUS_USER
    ) {
      consents = await this._storage?.get(STORAGE_KEY_CONSENT_LOCAL_STORAGE);
      if (consents) {
        this._gdprConsents = consents;
        this.logService.info(TAG, "GDPR consents loaded from local storage.", this._gdprConsents);
      } else {
        this.logService.info(TAG, "GDPR consents not found in local storage.");
      }
    } else if (this.globalService.currentAppMode() == AppMode.NORMAL) {
      try {
        let consentResponse = await this.iamService.getLastestGdprConsent();
        consents = JSON.parse(consentResponse.consent);
        if (consentResponse.consent !== null) {
          this._gdprConsents = JSON.parse(consentResponse.consent);
          this.logService.info(TAG, "GDPR consents loaded from server. ", consents);
        }
      } catch (err) {
        this.logService.error(TAG, "GDPR consents not loaded from server due to error. ", err);
      }
    }
    return this._gdprConsents;
  }

  async storeGdprConsents(consents: GdprConsent[]) {
    if (
      this.globalService.currentAppMode() == AppMode.DEMO ||
      this.globalService.currentAppMode() == AppMode.ANONYMOUS_USER
    ) {
      await this._storage.set(STORAGE_KEY_CONSENT_LOCAL_STORAGE, consents);
      this._gdprConsents = consents;
      this.logService.info(TAG, "GDPR consents stored in local storage. ", consents);
    } else if (this.globalService.currentAppMode() == AppMode.NORMAL) {
      this.iamService.saveGdprConsents(consents);
      this._gdprConsents = consents;
    }
  }

  useGdprConsents(consents: GdprConsent[]) {
    this._gdprConsents = consents;
  }

  async deleteLocalKeysByConsents() {
    if (this._gdprConsents) {
      this.logService.info(TAG, "Clear local data on the basis of a current GDPR consents.");
      this._storage.forEach((key, value, index) => {
        const consentTypeRequested = keyInConsent.get(value);
        const requiredConsent = this._gdprConsents.find(
          (consent) => consent.type == consentTypeRequested
        );
        if (!requiredConsent.accepted) {
          this.remove(value, true);
        }
      });
    }
  }

  async deleteAllLocalKeys() {
    this.logService.info(TAG, "Following data will be deleted upon user request:");
    await this._storage.forEach((key, value, index) => {
      this.logService.info(TAG, "   > " + value + ": " + key);
    });
    await this._storage.clear();
    this.logService.info(TAG, "Local data deleted.");
  }

  async deleteLocalFileCache() {
    Filesystem.rmdir({
      path: GlobalConstants.IMAGE_CACHE,
      directory: Directory.Cache,
      recursive: true,
    })
      .then((_) => {
        this.logService.info(TAG, "Local image cache cleared.");
        Filesystem.mkdir({
          directory: Directory.Cache,
          path: GlobalConstants.IMAGE_CACHE,
        }).catch((error) => {
          this.logService.error(TAG, "Error while preparing empty cache folder.", error);
        });
      })
      .catch((error) => {
        this.logService.error(TAG, "Error while deleting images from local cache.", error);
      });
  }

  deleteAllCookies() {
    this.cookieService.deleteAll("/");
    this.logService.info(TAG, "All cookies deleted.");
  }
}
