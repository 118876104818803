/**
 * @fileoverview gRPC-Web generated client stub for InkCapture
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as icws_proto_icws_api_gateway_searching_find_text_pb from '../../icws_proto/icws_api_gateway/searching/find_text_pb';


export class SearchingClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoFindText = new grpcWeb.AbstractClientBase.MethodInfo(
    icws_proto_icws_api_gateway_searching_find_text_pb.FindTextResponse,
    (request: icws_proto_icws_api_gateway_searching_find_text_pb.FindTextRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_searching_find_text_pb.FindTextResponse.deserializeBinary
  );

  findText(
    request: icws_proto_icws_api_gateway_searching_find_text_pb.FindTextRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_searching_find_text_pb.FindTextResponse>;

  findText(
    request: icws_proto_icws_api_gateway_searching_find_text_pb.FindTextRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: icws_proto_icws_api_gateway_searching_find_text_pb.FindTextResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_searching_find_text_pb.FindTextResponse>;

  findText(
    request: icws_proto_icws_api_gateway_searching_find_text_pb.FindTextRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: icws_proto_icws_api_gateway_searching_find_text_pb.FindTextResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Searching/FindText',
        request,
        metadata || {},
        this.methodInfoFindText,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Searching/FindText',
    request,
    metadata || {},
    this.methodInfoFindText);
  }

}

