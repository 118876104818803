import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import {
  ContentType,
  IccContent,
  IccDocument,
  IccFolder,
} from "src/app/models/icc-content.model";
import { GlobalService } from "src/app/services/global.service";
import { ContentLoadService } from "src/app/services/content-load.service";
import { SharedService } from "src/app/services/shared.service";

/**
 * Displays the content tree.
 * The tree can be navigated with the mouse with similar gestures as in MS Windows.
 */
@Component({
  selector: "app-content-tree",
  templateUrl: "./content-tree.component.html",
  styleUrls: ["./content-tree.component.scss"],
})
export class ContentTreeComponent implements OnInit, OnChanges {
  /** All items in storage in hierarchical form. */
  // @Input()
  private contentTree: IccContent[] = [];
  /** ID of node, which is currently focused in tree. */
  @Input() selectedNodeId: string;
  /** If set, nodes in tree are filtered according to given [ContentType]{@link ContentType} */
  @Input() limitToType: ContentType = null;
  /** Emits an event when selected node in tree is changed. */
  @Output() onNodeSelected: EventEmitter<IccContent> =
    new EventEmitter<IccContent>();
  /** Emits an event when selected document in tree is dblclicked */
  @Output() onDocumentDblclicked: EventEmitter<IccContent> =
    new EventEmitter<IccContent>();

  /** @ignore Variable for displaying the tree using Angular Material Tree component. */
  treeControl = new NestedTreeControl<IccContent>((node) => node.children);
  /** @ignore Variable for displaying the tree using Angular Material Tree component. */
  dataSource = new MatTreeNestedDataSource<IccContent>();

  /** @ignore Just to pass the enum to the template. */
  readonly contentType: typeof ContentType = ContentType;
  /** @ignore Just to pass the constant to the template. */
  rootNodeId: string;

  /** @ignore Mobile "dblclick" workaround. Catch single clicks and measure the time between clicks to recognize double click. */
  private touchTime = 0;
  /** @ignore To detect a "dblclick" it is necessary to remember the clicked item. A "dblclick" is only detected if both clicks are on the same item. */
  private touchedItemId: string = null;

  /** If true, selected node will be expanded in tree after its change.
   *  Used for single click event in tree, when the item should be selected but not expanded. */
  private selectedNodeIdChangeWithExpand: boolean = true;

  /** @ignore Indicates whether the current platform is mobile. Some elements then may change its appearance. */
  mobilePlatform: boolean = false;

  /** @ignore */
  constructor(
    private contentLoadService: ContentLoadService,
    private globalService: GlobalService
  ) {
    this.rootNodeId = this.contentLoadService.getRootId();
  }

  /** @ignore */
  ngOnInit() {
    this.contentLoadService.contentTreeObservable.subscribe((data) => {
      this.contentTree = SharedService.deepCopy<IccContent[]>(data);
      // save node's expanded state
      let prevExpansionModel = this.treeControl.expansionModel.selected;
      // update data source
      if (this.limitToType == ContentType.FOLDER) {
        this.dataSource.data = this.treeTypeFilter(
          this.contentTree,
          ContentType.FOLDER
        );
      } else {
        this.dataSource.data = this.contentTree;
      }
      // restore node's expanded state
      this.treeControl.collapseAll();
      prevExpansionModel.forEach((object) => {
        if (object) this.expandNode(object.icwsId, false);
      });
      if (this.selectedNodeIdChangeWithExpand) {
        this.expandNode(this.selectedNodeId, true);
      }
    });

    if (this.globalService.isMobilePlatform()) {
      this.mobilePlatform = true;
    }
  }

  /**
   * Expands the tree when selected node is changed (also when entire tree content or content filter is changed).
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes["selectedNodeId"]) {
      this.selectedNodeIdChangeWithExpand = true;
      this.expandNode(this.selectedNodeId, true);
    }
  }

  /**
   * Manages single click on item in tree. Item is selected and highlighted in tree.
   * @param {IccContentTree} item Selected item.
   */
  onItemClick(item: IccContent) {
    this.selectedNodeIdChangeWithExpand = false;
    this.selectedNodeId = item.icwsId;
    this.onNodeSelected.emit(item);
    this.recognizeDblClick(item);
  }

  /**
   * Recognizes and manages double click on item in tree. Item is selected and expanded/collapsed in tree.
   * In case of type DOCUMENT a special event is emitted.
   * Double click recognition is based on measuring time between two single clicks because on mobile device there is no dblclick event emitted.
   * @param {IccContent} item Selected item.
   */
  recognizeDblClick(item: IccContent) {
    if (this.touchTime == 0) {
      // set first click
      this.touchTime = new Date().getTime();
      this.touchedItemId = item.icwsId;
    } else {
      // compare first click to this click and see if they occurred within double click threshold
      if (
        new Date().getTime() - this.touchTime < 500 &&
        this.touchedItemId === item.icwsId
      ) {
        // double click occurred
        if (this.treeControl.isExpanded(item)) {
          this.treeControl.collapse(item);
        } else {
          this.treeControl.expand(item);
        }
        if (item.type === ContentType.DOCUMENT) {
          this.onDocumentDblclicked.emit(item);
        }

        this.touchTime = 0;
      } else {
        // not a double click so set as a new first click
        this.touchTime = new Date().getTime();
        this.touchedItemId = item.icwsId;
      }
    }
  }

  /**
   * Filter items in tree.
   * @param {IccContent[]} hierarchy The entire tree content.
   * @param {ContentType} itemTypeFilter Only nodes with this ContentType should left in the tree.
   * @returns {IccContent[]} New array with filtered tree content.
   */
  private treeTypeFilter(
    hierarchy: IccContent[],
    itemTypeFilter: ContentType
  ): IccContent[] {
    function filterData(node: IccContent[], itemType: ContentType): any {
      var result = node.filter((object: IccFolder | IccDocument) => {
        if (object.children && object.type != ContentType.PAGE) {
          // Page cannot have children
          object.children = filterData(object.children, itemType);
        }
        return object.type == itemType;
      });
      return result;
    }
    return filterData(hierarchy, itemTypeFilter);
  }

  /**
   * Expands the given node in tree. It is possible to expand only the node itself, or the whole path from the root.
   * @param {string} nodeIdToExpand Node to be expanded.
   * @param {boolean} expandWholePath If <code>true</code>, the whole path from the root will be expanded.
   */
  private expandNode(nodeIdToExpand: string, expandWholePath: boolean) {
    let expandedNode: IccContent = SharedService.findNodeInTree(
      this.dataSource.data,
      nodeIdToExpand
    );
    if (expandedNode) {
      this.treeControl.expand(expandedNode);
      if (expandWholePath) {
        if (expandedNode.parentId) {
          this.expandNode(expandedNode.parentId, true);
        }
      }
    }
  }

  /**
   * Function for rendering tree using Angular Material Tree component.
   * Detects whether given node should be in the tree expandable or not.
   * @returns {boolean} If <code>true</code>, given node should be expandable (has relevant children).
   */
  hasChild = (_: number, node: IccContent) =>
    !!node.children &&
    node.children.length > 0 &&
    node.type == ContentType.FOLDER;
}
