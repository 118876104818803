/**
 * @fileoverview gRPC-Web generated client stub for InkCapture
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as icws_proto_icws_api_gateway_processing_cancel_task_processing_pb from '../../icws_proto/icws_api_gateway/processing/cancel_task_processing_pb';
import * as icws_proto_icws_api_gateway_processing_get_task_progress_pb from '../../icws_proto/icws_api_gateway/processing/get_task_progress_pb';
import * as icws_proto_icws_api_gateway_processing_start_processing_pb from '../../icws_proto/icws_api_gateway/processing/start_processing_pb';


export class ProcessingClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoStartProcessing = new grpcWeb.AbstractClientBase.MethodInfo(
    icws_proto_icws_api_gateway_processing_start_processing_pb.StartProcessingResponse,
    (request: icws_proto_icws_api_gateway_processing_start_processing_pb.StartProcessingRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_processing_start_processing_pb.StartProcessingResponse.deserializeBinary
  );

  startProcessing(
    request: icws_proto_icws_api_gateway_processing_start_processing_pb.StartProcessingRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_processing_start_processing_pb.StartProcessingResponse>;

  startProcessing(
    request: icws_proto_icws_api_gateway_processing_start_processing_pb.StartProcessingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: icws_proto_icws_api_gateway_processing_start_processing_pb.StartProcessingResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_processing_start_processing_pb.StartProcessingResponse>;

  startProcessing(
    request: icws_proto_icws_api_gateway_processing_start_processing_pb.StartProcessingRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: icws_proto_icws_api_gateway_processing_start_processing_pb.StartProcessingResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Processing/StartProcessing',
        request,
        metadata || {},
        this.methodInfoStartProcessing,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Processing/StartProcessing',
    request,
    metadata || {},
    this.methodInfoStartProcessing);
  }

  methodInfoCancelTaskProcessing = new grpcWeb.AbstractClientBase.MethodInfo(
    icws_proto_icws_api_gateway_processing_cancel_task_processing_pb.CancelTaskProcessingResponse,
    (request: icws_proto_icws_api_gateway_processing_cancel_task_processing_pb.CancelTaskProcessingRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_processing_cancel_task_processing_pb.CancelTaskProcessingResponse.deserializeBinary
  );

  cancelTaskProcessing(
    request: icws_proto_icws_api_gateway_processing_cancel_task_processing_pb.CancelTaskProcessingRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_processing_cancel_task_processing_pb.CancelTaskProcessingResponse>;

  cancelTaskProcessing(
    request: icws_proto_icws_api_gateway_processing_cancel_task_processing_pb.CancelTaskProcessingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: icws_proto_icws_api_gateway_processing_cancel_task_processing_pb.CancelTaskProcessingResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_processing_cancel_task_processing_pb.CancelTaskProcessingResponse>;

  cancelTaskProcessing(
    request: icws_proto_icws_api_gateway_processing_cancel_task_processing_pb.CancelTaskProcessingRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: icws_proto_icws_api_gateway_processing_cancel_task_processing_pb.CancelTaskProcessingResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Processing/CancelTaskProcessing',
        request,
        metadata || {},
        this.methodInfoCancelTaskProcessing,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Processing/CancelTaskProcessing',
    request,
    metadata || {},
    this.methodInfoCancelTaskProcessing);
  }

  methodInfoGetTaskProgress = new grpcWeb.AbstractClientBase.MethodInfo(
    icws_proto_icws_api_gateway_processing_get_task_progress_pb.GetTaskProgressResponse,
    (request: icws_proto_icws_api_gateway_processing_get_task_progress_pb.GetTaskProgressRequest) => {
      return request.serializeBinary();
    },
    icws_proto_icws_api_gateway_processing_get_task_progress_pb.GetTaskProgressResponse.deserializeBinary
  );

  getTaskProgress(
    request: icws_proto_icws_api_gateway_processing_get_task_progress_pb.GetTaskProgressRequest,
    metadata: grpcWeb.Metadata | null): Promise<icws_proto_icws_api_gateway_processing_get_task_progress_pb.GetTaskProgressResponse>;

  getTaskProgress(
    request: icws_proto_icws_api_gateway_processing_get_task_progress_pb.GetTaskProgressRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: icws_proto_icws_api_gateway_processing_get_task_progress_pb.GetTaskProgressResponse) => void): grpcWeb.ClientReadableStream<icws_proto_icws_api_gateway_processing_get_task_progress_pb.GetTaskProgressResponse>;

  getTaskProgress(
    request: icws_proto_icws_api_gateway_processing_get_task_progress_pb.GetTaskProgressRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: icws_proto_icws_api_gateway_processing_get_task_progress_pb.GetTaskProgressResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/InkCapture.Processing/GetTaskProgress',
        request,
        metadata || {},
        this.methodInfoGetTaskProgress,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/InkCapture.Processing/GetTaskProgress',
    request,
    metadata || {},
    this.methodInfoGetTaskProgress);
  }

}

