import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  LocalStorageService,
  STORAGE_KEY_LANGUAGE,
} from "./local-storage.service";
import { LoggingService } from "./log.service";

/** @ignore */
const TAG = "LanguageService";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  constructor(
    private translate: TranslateService,
    private localStorage: LocalStorageService,
    private logService: LoggingService
  ) {}

  /** Set language based on the user settings or browser configuration. */
  async initialize(defaultLanguage: Object) {
    this.translate.setTranslation("en", defaultLanguage);
    this.translate.setDefaultLang("en");
    // Check whether the language has been previously set by the user
    const storedLang = await this.localStorage.get(STORAGE_KEY_LANGUAGE);
    if (!storedLang) {
      // If not, try to set language regarding to browser setting
      let browserLang = this.translate.getBrowserLang();
      if (this.getLanguages().some((lang) => lang.value == browserLang)) {
        this.translate.use(browserLang);
        this.logService.info(
          TAG,
          "Application language is set to '" +
            browserLang +
            "' according to the browser."
        );
      } else {
        // If browser language is not supported in the app, use english as default
        this.translate.use("en");
        this.logService.info(
          TAG,
          "Application language is set to default (en)."
        );
      }
    } else {
      this.translate.use(storedLang);
      this.logService.info(
        TAG,
        "Application language setting found and set to '" + storedLang + "'."
      );
    }
  }

  async setLanguage(language: string) {
    this.translate.use(language);
    await this.localStorage.set(STORAGE_KEY_LANGUAGE, language);
    this.logService.info(
      TAG,
      "Application language changed to '" + language + "'."
    );
  }

  getCurrentLanguage(): string {
    return this.translate.currentLang;
  }

  getLanguages() {
    return [
      { text: "Česky", value: "cs" },
      { text: "English", value: "en" },
    ];
  }
}
