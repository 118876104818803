// source: icws_proto/icws_api_gateway/storage/storage_types.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.InkCapture.BlockTypeType', null, global);
goog.exportSymbol('proto.InkCapture.ImageVariantType', null, global);
goog.exportSymbol('proto.InkCapture.WordSplitTypeType', null, global);
/**
 * @enum {number}
 */
proto.InkCapture.ImageVariantType = {
  NONE: 0,
  THUMB: 1,
  PREVIEW: 2,
  FULL: 3,
  ORIGINAL: 4
};

/**
 * @enum {number}
 */
proto.InkCapture.BlockTypeType = {
  UNKNOWN_BLOCK: 0,
  HW_TEXT_BLOCK: 1,
  TS_TEXT_BLOCK: 2,
  PICTURE_BLOCK: 3,
  MAP_BLOCK: 4
};

/**
 * @enum {number}
 */
proto.InkCapture.WordSplitTypeType = {
  UNKNOWN_WORD_SPLIT: 0,
  NOT_SPLIT: 1,
  FIRST_PART_SPLIT: 2,
  SECOND_PART_SPLIT: 3
};

goog.object.extend(exports, proto.InkCapture);
