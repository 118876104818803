import { animate, style, transition, trigger } from "@angular/animations";
import { LOCALE_ID, Inject, OnInit, OnDestroy } from "@angular/core";
import { Component } from "@angular/core";
import { ModalController, ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { AppMode, GlobalConstants } from "src/app/models/common";
import {
  ContentType,
  IccDocument,
  IccProcessingParams,
  IccTask,
} from "src/app/models/icc-content.model";
import { TermsAndConditionsComponent } from "src/app/pages/main/header/user-menu/terms-and-conditions/terms-and-conditions.component";
import {
  ProcessedScanStateType,
  TaskStateType,
} from "src/app/proto/generated/icws_proto/icws_api_gateway/types_pb";
import { ContentLoadService, LoadMode } from "src/app/services/content-load.service";
import { GlobalService } from "src/app/services/global.service";
import { IamService } from "src/app/services/iam.service";
import { LoggingService } from "src/app/services/log.service";
import { OcrService } from "src/app/services/page-ocr.service";
import { ProcessingService } from "src/app/services/processing.service";

/** @ignore */
const TAG = "OcrPopup";

@Component({
  selector: "app-run-ocr-popup",
  templateUrl: "./run-ocr-popup.component.html",
  styleUrls: ["./run-ocr-popup.component.scss"],
  animations: [
    trigger("ocrParamsAnimation", [
      transition(":enter", [
        style({ height: "0px" }),
        animate("0.3s ease-out", style({ height: "80px" })),
      ]),
      transition(":leave", [
        style({ height: "80px" }),
        animate("0.3s ease-in", style({ height: "0px" })),
      ]),
    ]),
  ],
})
export class RunOcrPopupComponent implements OnInit, OnDestroy {
  documentId: string;
  documentName: string;
  documentPageCount: number;
  ocrSelectedParams: string;
  ocrAvailableParams: IccProcessingParams[];
  docOcrTasks: IccTask[];
  showOcrOptions: boolean = true;
  pageDetailText: string = "";
  private updateOcrStateSubscription: Subscription;
  termsAccepted: boolean = false;
  credit: number = 0;

  /** @ignore Just to pass the enum to the template. */
  readonly taskStateType = TaskStateType;

  private ocrParamsDetail: { id: string; label: string; description: string }[] = [
    {
      id: GlobalConstants.OCR_PROCESSING_PARAMS_GENERAL_ID,
      label: "runOcr.optModelGenLabel",
      description: "runOcr.optModelGenDesc",
    },
    {
      id: "kurrent",
      label: "runOcr.optModelKurrLabel",
      description: "runOcr.optModelKurrDesc",
    },
  ];

  ocrLogResult: Array<{
    key: TaskStateType;
    label: string;
  }> = [
    {
      key: TaskStateType.TASK_STATE_TYPE_UNKNOWN,
      label: "runOcr.taskStateUnknownLabel",
    },
    {
      key: TaskStateType.TASK_STATE_TYPE_NEW,
      label: "runOcr.taskStateNewLabel",
    },
    {
      key: TaskStateType.TASK_STATE_TYPE_IN_PROGRESS,
      label: "runOcr.taskStateInProgLabel",
    },
    {
      key: TaskStateType.TASK_STATE_TYPE_PAUSED,
      label: "runOcr.taskStatePausedLabel",
    },
    {
      key: TaskStateType.TASK_STATE_TYPE_FINISHED_OK,
      label: "runOcr.taskStateFinOkLabel",
    },
    {
      key: TaskStateType.TASK_STATE_TYPE_FINISHED_ERR,
      label: "runOcr.taskStateFinErrLabel",
    },
    {
      key: TaskStateType.TASK_STATE_TYPE_ABORTED,
      label: "runOcr.taskStateAbortedLabel",
    },
    {
      key: TaskStateType.TASK_STATE_TYPE_CANCELLED,
      label: "runOcr.taskStateCancelledLabel",
    },
  ];

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private contentloadService: ContentLoadService,
    private modalController: ModalController,
    private toastController: ToastController,
    private processingService: ProcessingService,
    private ocrService: OcrService,
    private logService: LoggingService,
    private translate: TranslateService,
    private globalService: GlobalService,
    private iamService: IamService
  ) {
    this.ocrAvailableParams = this.ocrService.getProcessingParams();
    this.ocrSelectedParams = GlobalConstants.OCR_PROCESSING_PARAMS_GENERAL_ID;
  }

  ngOnInit() {
    this.contentloadService
      .getNode(this.documentId, LoadMode.LOAD_WHEN_NEEDED, ContentType.DOCUMENT)
      .then((doc) => {
        this.documentName = doc.name;
        this.documentPageCount = (<IccDocument>doc).scans_num;
      });
    this.processingService.getDocumentOcrStateInfo(this.documentId).then((ocrTasks) => {
      this.docOcrTasks = ocrTasks.taskList;
      if (this.docOcrTasks.length > 0) {
        this.showOcrOptions = false;
      } else {
        this.showOcrOptions = true;
      }
    });

    this.updateOcrStateSubscription = this.processingService.docOcrStateChange.subscribe(
      (updateDocumentId) => {
        if (this.documentId == updateDocumentId) {
          this.processingService.getDocumentOcrStateInfo(this.documentId).then((ocrTasks) => {
            this.docOcrTasks = ocrTasks.taskList;
          });
        }
      }
    );

    this.iamService.getAccountState().then((account) => {
      this.credit = account.availableBudget;
    });
  }

  /** Unsubscribe from document ocr state refresh subscription */
  ngOnDestroy() {
    if (this.updateOcrStateSubscription) {
      this.updateOcrStateSubscription.unsubscribe();
    }
  }

  getOcrTaskStateLabel(ocrTaskState: TaskStateType): string {
    return this.ocrLogResult.find((e) => e.key === ocrTaskState).label;
  }

  saveModelSettings(model: any) {
    this.ocrSelectedParams = model.detail.value;
  }

  toggleShowOcrOptions() {
    this.showOcrOptions = !this.showOcrOptions;
  }

  async onRunOcrTask() {
    if (!this.termsAccepted) {
      return;
    }
    if (this.globalService.currentAppMode() === AppMode.NORMAL) {
      let knownTask: boolean = false;
      const procParams = this.ocrAvailableParams.find((item) => item.id === this.ocrSelectedParams);
      if (procParams) {
        this.processingService
          .startDocProcessing(this.documentId, procParams)
          .then(async (response) => {
            response.getDocumentsTasksMap().forEach((value: string, key: string) => {
              if (this.docOcrTasks.find((item) => item.icwsId === value)) {
                knownTask = true;
              }
            });
            if (knownTask) {
              const toast = await this.toastController.create({
                message: this.translate.instant("runOcr.sameOcrMessage"),
                position: "top",
                cssClass: "toast",
                duration: 4000,
              });
              toast.present();
              this.logService.info(
                TAG,
                'Document "' +
                  this.documentId +
                  '": task with the same parameters has already been processed, new task has not been started.'
              );
            } else {
              this.logService.info(
                TAG,
                'New ocr task for document "' + this.documentId + '" has been started.'
              );
              this.showOcrOptions = false;
              this.processingService.SetDocumentWithPagesInProgressInOcrStateCache(this.documentId);
              this.docOcrTasks = (
                await this.processingService.getDocumentOcrStateInfo(this.documentId, true)
              ).taskList;
            }
            this.termsAccepted = false;
          });
      } else {
        const toast = await this.toastController.create({
          message: this.translate.instant("runOcr.ocrParamsNotFound"),
          position: "top",
          cssClass: "toast",
          duration: 4000,
        });
        toast.present();
      }
    } else {
      const toast = await this.toastController.create({
        message: this.translate.instant("runOcr.ocrDemoToast"),
        position: "top",
        cssClass: "toast",
        duration: 4000,
      });
      toast.present();
    }
  }

  onCancelOcrTask(taskId: string) {
    this.processingService.cancelTaskProcessing(taskId).then(async (_) => {
      this.logService.info(TAG, 'Ocr task "' + taskId + '" has been cancelled.');
      this.docOcrTasks = (
        await this.processingService.getDocumentOcrStateInfo(this.documentId)
      ).taskList;
    });
  }

  async onDismiss(result: string) {
    if (this.updateOcrStateSubscription) {
      this.updateOcrStateSubscription.unsubscribe();
    }
    await this.modalController.dismiss(result);
  }

  getOcrParamsDetail(paramsId: string): { id: string; label: string; description: string } {
    const params = this.ocrParamsDetail.find((item) => item.id === paramsId);
    return params;
  }

  sortTasks(tasks: IccTask[]): IccTask[] {
    return tasks.sort((a, b) =>
      a.created_at < b.created_at ? 1 : a.created_at === b.created_at ? 0 : -1
    );
  }

  pageNumText(num: number): string {
    if (num) {
      if (num === 1) {
        return "(" + num + " " + this.translate.instant("runOcr.taskDet1Page") + ")";
      } else if (num >= 2 && num <= 4) {
        return "(" + num + " " + this.translate.instant("runOcr.tasDet2Pages") + ")";
      } else if (num >= 5) {
        return "(" + num + " " + this.translate.instant("runOcr.taskDet5Pages") + ")";
      }
    } else {
      return "";
    }
  }

  taskTooltip(task: IccTask): string {
    let tooltip = "";
    const okCount: number = task.pageNumFinished[
      ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_FINISHED_OK
    ]
      ? task.pageNumFinished[ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_FINISHED_OK]
      : 0;
    const errCount: number = task.pageNumFinished[
      ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_FINISHED_ERR
    ]
      ? task.pageNumFinished[ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_FINISHED_ERR]
      : 0;
    const inProgressCount: number = task.pageNumFinished[
      ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_IN_PROGRESS
    ]
      ? task.pageNumFinished[ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_IN_PROGRESS]
      : 0;
    const canceledCount: number = task.pageNumFinished[
      ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_CANCELLED
    ]
      ? task.pageNumFinished[ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_CANCELLED]
      : 0;
    const abortedCount: number = task.pageNumFinished[
      ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_ABORTED
    ]
      ? task.pageNumFinished[ProcessedScanStateType.PROCESSED_SCAN_STATE_TYPE_ABORTED]
      : 0;

    if (Object.keys(task.pageNumFinished).length === 0) {
      tooltip = this.translate.instant("runOcr.taskDetNothing");
    } else {
      if (okCount > 0) {
        tooltip += this.translate.instant("runOcr.taksDetWithoutErr", { pageCount: okCount });
      }
      if (errCount > 0) {
        tooltip += this.translate.instant("runOcr.taksDetWitErr", { pageCount: okCount });
      }
      if (inProgressCount > 0) {
        tooltip += this.translate.instant("runOcr.taskDetInProg", { pageCount: okCount });
      }
      if (canceledCount > 0) {
        tooltip += this.translate.instant("runOcr.taskDetCancel", { pageCount: okCount });
      }
      if (abortedCount > 0) {
        tooltip += this.translate.instant("runOcr.taskDetAbort", { pageCount: okCount });
      }
    }
    return tooltip;
  }

  async onTermsAndConditions() {
    const modal = await this.modalController.create({
      component: TermsAndConditionsComponent,
      cssClass: "large-modal",
    });
    await modal.present();
  }

  /** @ignore
   * CSS for scrollbar.
   */
  scrollbarStyle = `
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-track:hover {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #a1a1a1;
      border-radius: 6px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #8e8e8e;
    }
    .inner-scroll {
      scrollbar-width: thin;
    }`;
}
