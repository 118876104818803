/**
 * Item marked for bulk editing.
 */
export interface MarkedItem {
  /** Item index in the list of children of the parent item. */
  index: number;
  /** Required action (e.g. delete, move). */
  action: ActionType;
  /** Icon related with required action (It may appear on the item as an indication of the desired action). */
  icon: string;
  /** Text related with required action (It may appear on the item as a specification of the desired action). */
  text: string;
  /** Property used to store the ID of the destination folder in case of move operation. */
  destination: string;
}

/**
 * The structure used to select text when viewing a document page.
 * Uses the [StructuredContent]{@link StructuredContent} structure.
 */
export interface SelectedContent {
  /** Selected line index in [StructuredContent]{@link StructuredContent} structure. */
  lineIndex: number;
  /** Selected word index in line in [StructuredContent]{@link StructuredContent} structure. */
  wordIndex: number;
}

/**
 * Possible bulk operations with items.
 */
export enum ActionType {
  NONE = 0,
  SELECT = 1,
  DELETE = 2,
  MOVE = 3,
}

/**
 * Statuses, which are possible during saving changes to server.
 */
export enum SavingStatus {
  NONE = 0,
  SAVING = 1,
  SAVE_OK = 2,
  SAVE_ERR = 3,
  RIGHTS_ERR = 4,
}

/**
 * Options for which objects should be highlighted when viewing a document page (user-set option).
 */
export enum HighlightType {
  None, // No highlight
  Lines, // Highlight all lines
  AllWords, // Highlight all words
  All, // Highlight all lines and words
  SelectedWords, // Highlight only words related with 'selectedContent'
}

/**
 * Options for which texts should be highlighted when viewing a document page (user-set option).
 */
export enum ShowTextType {
  None, // No text will be displayed
  AllWords, // Display all texts
  SelectedWords, // Display only text related with 'selectedContent'
  TextSize,
}

export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Off = 5,
}

export enum LogOutput {
  Console = 0,
  File = 1,
}
