import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { ContentType, IccContent } from "src/app/models/icc-content.model";
import { ContentLoadService } from "src/app/services/content-load.service";

/**
 * Variant of content tree display for selecting the destination when moving a folder/document.
 * Tree is displayed as a modal window over the main screen.
 */
@Component({
  selector: "app-modal-tree",
  templateUrl: "./modal-tree.component.html",
  styleUrls: ["./modal-tree.component.scss"],
})
export class ContentTreeModalComponent implements OnInit {
  /** Node, which is currently selected in tree panel and which properties will be returned as a result. */
  selectedNode: IccContent;

  /** If set, nodes in tree are filtered according to given [ContentType]{@link ContentType} */
  limitToType: ContentType = null;

  /** These nodes cannot be selected in the tree. */
  disabledNodes: string[] = [];

  /** Caption of modal window. It is set depending on [limitToType]{@link ContentTreeModalComponent#limitToType} property. */
  windowCaption: string;

  /** @ignore Just to pass the constant to the template. */
  rootNodeId: string;

  /** @ignore */
  constructor(
    private modalController: ModalController,
    private translate: TranslateService,
    private contentLoadService: ContentLoadService
  ) {
    this.rootNodeId = this.contentLoadService.getRootId();
  }

  /**
   * Loads storage content and initialize data to display.
   */
  ngOnInit() {
    this.windowCaption =
      this.limitToType == ContentType.DOCUMENT
        ? this.translate.instant("content-tree-modal.selDestDoc")
        : this.translate.instant("content-tree-modal.selDestFol");
  }

  /**
   * Returns value from modal window - properties of selected node.
   */
  async returnSelectedItem() {
    await this.modalController.dismiss({
      itemId: this.selectedNode.icwsId,
      itemName: this.selectedNode.name,
    });
  }

  async onClose() {
    this.modalController.dismiss(null);
  }

  /**
   * Determines whethter the node is in the list of disabled nodes.
   * @param {IccContent} node Node to be evaluated.
   * @returns {boolean} If <code>true</code>, the node is in the list of disabled nodes.
   */
  isNodeDisabled(node: IccContent): boolean {
    let isDisabled: boolean = false;
    for (let item of this.disabledNodes) {
      if (node.icwsId === item) {
        isDisabled = true;
        break;
      }
    }
    return isDisabled;
  }
}
