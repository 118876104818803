import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { PDFDocumentProxy, PDFProgressData } from "ng2-pdf-viewer";

@Component({
  selector: "app-pdf-view",
  templateUrl: "./pdf-view.component.html",
  styleUrls: ["./pdf-view.component.scss"],
})
export class PdfViewComponent implements OnInit {
  pdfSrc = "";
  isLoading: boolean = true;
  originalSize: boolean = true;

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  /**
   * Closes modal window.
   */
  async closeWindow() {
    await this.modalController.dismiss();
  }

  onLoadComplete(pdf: PDFDocumentProxy) {
    this.isLoading = false;
  }
}
