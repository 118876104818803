import { LogLevel, LogOutput } from "src/app/models/ui.model";
import { AppMode } from "src/app/models/common";
import { MockOCRType } from "src/app/services/mock-data.service";

/** @ignore */
export const environment = {
  app_mode: AppMode.NORMAL,
  production: false,
  icc_client: "http://inkcapture-dev.amitia-ai.com",
  app_version: require("../../package.json").version + "-dev",
  icws_server: "https://inkcapture-ws-proxy-dev.amitia-ai.com:443",
  smartemailing_url_cs:
    "https://app.smartemailing.cz/public/web-forms-v2/display-form/16747-tqova3symuf2orvhaj077jdlcj4bavqdv1rbhpoiltpx38i4i1yrtfjv5t8hxofa4z2vns207xu4nxjj6ka4df0kts5y78u99wl3",
  smartemailing_url_en:
    "https://app.smartemailing.cz/public/web-forms-v2/display-form/16747-b4ch5p8tgz01w8t5phzir1nxbf5n5pa0czoifwqtep1oudck0dmlxuz3ygwz3c9m0eibigh1qtrb9xr6fyuzbmpyqvzn4dsex4aj",
  smartemailing_msg_field: "fields[cf_12]",
  google_analytics_id: "UA-15325943-5",
  mock_data: false,
  mock_ocr_type: MockOCRType.JSON_COCO,
  log_level_local: LogLevel.All,
  log_output_local: LogOutput.Console,
  log_level_server: LogLevel.Warn,
  iam_client: "https://exon-iam-dev.amitia-ai.com",
  iam_server: "https://exon-iam-be-dev.amitia-ai.com",
  allowed_domains: ["exon-iam-be-dev.amitia-ai.com"],
  // iam_server: "https://iam-development-g4uxkfjhjq-ew.a.run.app",
  // allowed_domains: ["iam-development-g4uxkfjhjq-ew.a.run.app"],
  anonymous_user_token:
    "eyJhbGciOiJFZERTQSIsInR5cCI6IkpXVCJ9.eyJleHAiOjE4OTM0NTYwMDAsImlhdCI6MTU3NzgzNjgwMCwiaXNfZXhvbl9hZG1pbiI6ZmFsc2UsImxpY2VuY2VfaWQiOiI2MjhiYTJkOWYwZGZlNWFlYTUwMGQ5YmYiLCJsaWNlbnNlX2lkIjoiNjI4YmEyZDlmMGRmZTVhZWE1MDBkOWJmIiwibmFtZSI6IklDQyBFeG9uIiwicmVsZWFzZV9pZCI6IjYwYmU0ZGVmMzJkZGFkMjVkMmZmNjE2YSIsInNlY3JldF90b2tlbiI6IlRPRE8iLCJzdWIiOiJwdWJsaWMucmVhZGVyQGlua2NhcHR1cmUuY29tIiwidXNlcl9pZCI6IjYyOGJhMmQ5ZjBkZmU1YWVhNTAwZDljMCJ9.jXip_fRjA9Wf8OV1npDv0PvD5F0mLctfja2euZbJh_937KW3K4e0APAsHpXI2C2AM5iOsUfwc2bwbA9kYHyvAQ",
  override_login_token: "",
  restricted_functions_whitelist: ["jan.valenta@exon.cz"],
};
