import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { IonTextarea, ModalController } from "@ionic/angular";

@Component({
  selector: "app-edit-popup",
  templateUrl: "./edit-popup.component.html",
  styleUrls: ["./edit-popup.component.scss"],
})
export class EditPopupComponent implements AfterViewInit {
  itemLabel: string;
  itemValue: string;
  selectText: boolean = true;

  @ViewChild("pageNameTextarea")
  pageNameTextarea: IonTextarea;

  constructor(private modalController: ModalController) {}

  async ngAfterViewInit() {
    setTimeout(() => this.setFocus(), 1000);
  }

  async setFocus() {
    let inputElement = await this.pageNameTextarea.getInputElement();
    inputElement.focus();
    inputElement.setSelectionRange(
      this.selectText ? 0 : this.itemValue.length,
      this.itemValue.length
    );
  }

  async onDismiss(newPageName: string) {
    await this.modalController.dismiss(newPageName);
  }
}
