import { Component, HostBinding, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-side-panel-container",
  templateUrl: "./side-panel-container.component.html",
  styleUrls: ["./side-panel-container.component.scss"],
})
export class SidePanelContainerComponent implements OnInit {
  @Input() topGap: number = 72;

  @HostBinding("style.height") containerHeight =
    "calc(100% - " + this.topGap + "px)";
  @HostBinding("style.top") containerTop = this.topGap + "px";

  constructor() {}

  ngOnInit() {}
}
