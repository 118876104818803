import { Component, OnInit, Renderer2 } from "@angular/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Platform } from "@ionic/angular";
import { GlobalService } from "./services/global.service";
import { LoggingService } from "./services/log.service";
import { LogLevel, LogOutput } from "src/app/models/ui.model";
import { environment } from "src/environments/environment";
import { GlobalConstants } from "src/app/models/common";
import { AuthenticationService } from "./services/authentication.service";
import { IcwsStorageService } from "./services/icws-storage.service";

/** @ignore */
const TAG = "AppComponent";

/**
 * Init global data and services.
 */
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  /** @ignore */
  constructor(
    private authService: AuthenticationService,
    private icwsStorageService: IcwsStorageService,
    private global: GlobalService,
    private logService: LoggingService,
    private renderer: Renderer2,
    private platform: Platform
  ) {
    // Load authentication token
    this.authService.loadToken();

    this.platform.ready().then(() => {
      // initialize cache folder
      this.createCacheFolder();

      // clean old files in cache
      this.icwsStorageService.cleanLocalCache(
        Directory.Cache,
        GlobalConstants.CACHE_CLEAR_INTERVAL
      );

      // inform about log level
      this.logService.info(
        TAG,
        "Log level set to '" +
          LogLevel[environment.log_level_local] +
          "' (output to " +
          LogOutput[environment.log_output_local] +
          ")."
      );
    });
  }

  /**
   * Initializes global variables, sets the environment, and performs the initial load of data from the server.
   */
  ngOnInit() {
    // set initial color theme
    this.renderer.setAttribute(document.body, "color-theme", "iccGray");

    // for ios platform perform a "hack" to prevent default Safari gestures "pinch to zoom"
    this.platform.ready().then(() => {
      if (this.global.isIOSPlatform()) {
        this.preventIOSpinchZoom();
      }
    });
  }

  /**
   * Creates a local folder (if it does not exist) for data caching.
   */
  private async createCacheFolder() {
    try {
      await Filesystem.mkdir({
        directory: Directory.Cache,
        path: GlobalConstants.IMAGE_CACHE,
      });
    } catch (error) {}
  }

  /**
   * "Hack" to prevent default zoom action in iOS Safari
   * source: https://stackoverflow.com/a/52201924
   */
  preventIOSpinchZoom() {
    document.addEventListener("gesturestart", function (e) {
      e.preventDefault();
      this.renderer.setAttribute(document.body, "style.zoom", "1");
    });
    document.addEventListener("gesturechange", function (e) {
      e.preventDefault();
      this.renderer.setAttribute(document.body, "style.zoom", "1");
    });
    document.addEventListener("gestureend", function (e) {
      e.preventDefault();
      this.renderer.setAttribute(document.body, "style.zoom", "1");
    });
  }
}
