import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-error-window",
  templateUrl: "./error-window.component.html",
  styleUrls: ["./error-window.component.scss"],
})
export class ErrorWindowComponent implements OnInit {
  errorText: string = "Unknown application error";

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  async onDismiss() {
    await this.modalController.dismiss();
  }
}
