// source: icws_proto/icws_api_gateway/types.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.InkCapture.DocumentStateType', null, global);
goog.exportSymbol('proto.InkCapture.LogLevelType', null, global);
goog.exportSymbol('proto.InkCapture.ProcessedScanStateType', null, global);
goog.exportSymbol('proto.InkCapture.QuotaType', null, global);
goog.exportSymbol('proto.InkCapture.ScanStateType', null, global);
goog.exportSymbol('proto.InkCapture.TaskStateType', null, global);
goog.exportSymbol('proto.InkCapture.UpdateStrategyType', null, global);
/**
 * @enum {number}
 */
proto.InkCapture.UpdateStrategyType = {
  UPDATE_STRATEGY_UNKNOWN: 0,
  UPDATE_STRATEGY_REPLACE: 1,
  UPDATE_STRATEGY_MERGE: 2
};

/**
 * @enum {number}
 */
proto.InkCapture.QuotaType = {
  UNKNOWN_QUOTA_TYPE: 0,
  REQUESTS_NUM_QUOTA_TYPE: 1,
  SINGLE_REQUEST_DATA_SIZE_MB_QUOTA_TYPE: 2,
  REQUESTS_DATA_SIZE_MB_QUOTA_TYPE: 3,
  STORED_DATA_SIZE_MB_QUOTA_TYPE: 4
};

/**
 * @enum {number}
 */
proto.InkCapture.TaskStateType = {
  TASK_STATE_TYPE_UNKNOWN: 0,
  TASK_STATE_TYPE_NEW: 1,
  TASK_STATE_TYPE_IN_PROGRESS: 2,
  TASK_STATE_TYPE_PAUSED: 3,
  TASK_STATE_TYPE_FINISHED_OK: 11,
  TASK_STATE_TYPE_FINISHED_ERR: 21,
  TASK_STATE_TYPE_ABORTED: 22,
  TASK_STATE_TYPE_CANCELLED: 23
};

/**
 * @enum {number}
 */
proto.InkCapture.ProcessedScanStateType = {
  PROCESSED_SCAN_STATE_TYPE_UNKNOWN: 0,
  PROCESSED_SCAN_STATE_TYPE_NEW: 1,
  PROCESSED_SCAN_STATE_TYPE_IN_PROGRESS: 2,
  PROCESSED_SCAN_STATE_TYPE_FINISHED_OK: 11,
  PROCESSED_SCAN_STATE_TYPE_FINISHED_ERR: 21,
  PROCESSED_SCAN_STATE_TYPE_ABORTED: 22,
  PROCESSED_SCAN_STATE_TYPE_CANCELLED: 23
};

/**
 * @enum {number}
 */
proto.InkCapture.ScanStateType = {
  SCAN_STATE_TYPE_UNKNOWN: 0,
  SCAN_STATE_TYPE_NEW: 1,
  SCAN_STATE_TYPE_IN_PROGRESS: 2,
  SCAN_STATE_TYPE_FINISHED_OK: 11,
  SCAN_STATE_TYPE_FINISHED_ERR: 21
};

/**
 * @enum {number}
 */
proto.InkCapture.DocumentStateType = {
  DOCUMENT_STATE_TYPE_UNKNOWN: 0,
  DOCUMENT_STATE_TYPE_NEW: 1,
  DOCUMENT_STATE_TYPE_IN_PROGRESS: 2,
  DOCUMENT_STATE_TYPE_FINISHED_OK: 11,
  DOCUMENT_STATE_TYPE_FINISHED_ERR: 21
};

/**
 * @enum {number}
 */
proto.InkCapture.LogLevelType = {
  LOG_LEVEL_TYPE_NOT_SET: 0,
  LOG_LEVEL_TYPE_DEBUG: 10,
  LOG_LEVEL_TYPE_INFO: 20,
  LOG_LEVEL_TYPE_WARNING: 30,
  LOG_LEVEL_TYPE_ERROR: 40,
  LOG_LEVEL_TYPE_CRITICAL: 50
};

goog.object.extend(exports, proto.InkCapture);
