import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  LogRequest,
  LogResponse,
} from "../proto/generated/icws_proto/icws_api_gateway/logging/log_pb";
import { LoggingClient } from "../proto/generated/icws_proto/icws_api_gateway/LoggingServiceClientPb";
import { LogLevelType } from "../proto/generated/icws_proto/icws_api_gateway/types_pb";
import { SharedService } from "./shared.service";

const TAG = "IcwsLoggingService";

@Injectable({
  providedIn: "root",
})
export class IcwsLoggingService {
  loggingService: LoggingClient;
  private _authMetadata = { authorization: null };

  constructor() {
    this.loggingService = new LoggingClient(environment.icws_server, null, null);
  }

  async initMetadata(token: string) {
    if (token !== null) {
      this._authMetadata.authorization = token;
    }
  }

  clearMetadata() {
    this._authMetadata.authorization = null;
  }

  log(logType: LogLevelType, message: string): Promise<LogResponse> {
    const reqGUID: string = SharedService.generateGuid();
    const request = new LogRequest();
    request.setRequestId(reqGUID);
    request.setLevel(logType);
    request.setMessage(message);
    return this.loggingService.log(request, this._authMetadata);
  }
}
